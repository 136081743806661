import { VStack, FormControl } from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { DatePicker } from "../time/DatePicker";
import { today, getLocalTimeZone } from "@internationalized/date";

export default function DateInput({
  label,
  helpTitle,
  helpContent,
  value,
  onUpdate,
}) {
  return (
    <VStack w="100%" align="left">
      <Formik
        initialValues={{
          key: value || "",
        }}
        onSubmit={async (form, { resetForm }) => {
          const result = onUpdate(form?.key);
          if (!result) {
            resetForm();
          }
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="key">
              {({ field, form }) => (
                <FormControl id="key">
                  <DatePicker
                    label={label}
                    helpTitle={helpTitle}
                    helpContent={helpContent}
                    granularity="minute"
                    minValue={today(getLocalTimeZone())}
                    defaultValue={field.value}
                    onChange={(val) => {
                      form.setFieldValue(field.name, val.toAbsoluteString());
                    }}
                    onClose={() => {
                      handleSubmit(form);
                    }}
                  />
                </FormControl>
              )}
            </Field>
          </form>
        )}
      </Formik>
    </VStack>
  );
}
