import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import { TbWorld } from "react-icons/tb";
import { FiBook } from "react-icons/fi";

import { GoBackButton } from "../utils/Buttons";
import { AccountAPI } from "../../api/AccountAPI";
import EditableSwitch from "../utils/editable/EditableSwitch";
import GenericEditableInput from "../utils/editable/Generic";
import { AccountAddress } from "../utils/editable/Address";
import {
  ExecutiveContacts,
  OperationsContacts,
  ItContacts,
  BillingContacts,
} from "../utils/editable/AccountContact";
import { AccountContext } from "../../context/AccountContext";

const AccountInfo = () => {
  const params = useParams();
  const accountId = params.accountId;
  const [getAccountInfo, accountInfo, accountInfoLoaded] =
    AccountAPI.accountById();
  const updateAccountByID = AccountAPI.updateAccount();

  useEffect(() => {
    getAccountInfo(accountId);
  }, []);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {"Account: " + accountInfo?.name || ""}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {accountInfoLoaded ? (
              GetBoxInfo(accountInfo, getAccountInfo, updateAccountByID)
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function GetBoxInfo(account, getAccountInfo, updateAccountByID) {
  const workingAccount = useContext(AccountContext);
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<FiBook color="gray.800" />}
        value={account?.name}
        help="This is your organization or account name"
        placeholder="Your organization name"
        onUpdate={(new_name) => {
          updateAccountByID(account.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload account info
            if (response?.status == 200) {
              getAccountInfo(account.id);
              workingAccount.refresh();
            }
          });
        }}
      />

      <AccountAddress account_id={account?.id} />
      <ExecutiveContacts account_id={account?.id} />
      <BillingContacts account_id={account?.id} />
      <ItContacts account_id={account?.id} />
      <OperationsContacts account_id={account?.id} />
      <GenericEditableInput
        label="Website"
        icon={<TbWorld color="gray.800" />}
        value={account?.website}
        help="This is your organization or account website"
        placeholder="Your organization website"
        onUpdate={(new_website) => {
          updateAccountByID(account.id, {
            website: new_website,
          }).then(() => {
            // After updating we need to reload account info
            getAccountInfo(account.id);
          });
        }}
      />
      <EditableSwitch
        label="Account Enabled"
        value={account?.enabled}
        help="If an account is disabled their users can not access to the system
              or use it."
        onUpdate={(new_enabled) => {
          updateAccountByID(account.id, {
            enabled: new_enabled,
          }).then((response) => {
            // After updating we need to reload group info
            if (response?.status == 200) {
              getAccountInfo(account.id);
            }
          });
        }}
      />
    </VStack>
  );
}

export default AccountInfo;
