import React, { useMemo, useCallback } from "react";
import {
  Heading,
  Stack,
  VStack,
  StackDivider,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { SessionAPI } from "../../api/SessionAPI";
import { FilterTable, TableHeader } from "../utils/FilterTable";

const MySessions = () => {
  const [requestSessions, sessionsAmount, sessions] = SessionAPI.sessionsMe();

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader title="ID" help="Session unique identifier." />,
        accessor: "getID",
      },
      {
        Header: (
          <TableHeader
            title="License ID"
            help="License used by this session."
          />
        ),
        accessor: "getLicenseID",
      },
      {
        Header: <TableHeader title="Start time" help="Session start time." />,
        accessor: "getCreatedAt",
      },
      {
        Header: <TableHeader title="End time" help="Session end time." />,
        accessor: "getEndTime",
      },
      {
        Header: (
          <TableHeader
            title="Status"
            help={
              <UnorderedList>
                <ListItem>Active: the session is running. </ListItem>
                <ListItem>
                  Refreshed: the session was refreshed to keep using it.
                </ListItem>
                <ListItem>
                  Finished by user: the session was completed.
                </ListItem>
                <ListItem>
                  Aborted: the session was interrupted by the admin account.
                </ListItem>
                <ListItem>
                  Failed: there was an error trying to start the session.
                </ListItem>
              </UnorderedList>
            }
          />
        ),
        accessor: "getStatus",
      },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (sessions !== null && sessions !== undefined) {
      sessions.map((session) => {
        values.push({
          getID: session.id,
          getLicenseID: session.license_id,
          getCreatedAt: session.created_at,
          getEndTime: session.end,
          getStatus: convertSessionStatusToString(
            session.status,
            session.is_active
          ),
        });
      });
    }

    return values;
  }, [sessions]);

  const fetchData = useCallback((pageSize, pageIndex) => {
    requestSessions(pageSize * pageIndex, pageSize);
  }, []);

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Sessions Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Sessions"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={sessionsAmount}
        resetTableDependencies={[]}
      />
    </VStack>
  );
};

const convertSessionStatusToString = (status, is_active) => {
  const Status = {
    Started: 1,
    Refreshed: 2,
    Closed: 3,
    Aborted: 4,
    Failed: 5,
  };

  switch (status) {
    case Status.Started:
      return is_active ? "Active" : "Finished by timeout";
    case Status.Refreshed:
      return is_active ? "Refreshed" : "Finished by timeout";
    case Status.Closed:
      return "Finished by user";
    case Status.Aborted:
      return "Aborted";
    case Status.Failed:
      return "Failed";
  }

  return "Unknown";
};

export default MySessions;
