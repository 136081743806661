import React, { useMemo, useContext, useCallback } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";

import { GroupAPI } from "../../api/GroupAPI";
import { AccountContext } from "../../context/AccountContext";
import { FilterTable, TableHeader } from "../utils/FilterTable";
import { GoToEditPageButton } from "../utils/Buttons";

const GroupsTable = () => {
  const workingAccount = useContext(AccountContext);
  const [getGroupsByAccount, groupsAmount, groups] = GroupAPI.groupsByAccount();

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader title="Name" help="Group name." />,
        accessor: "getName",
      },
      {
        Header: (
          <TableHeader
            title="Enabled"
            help="If a group is disabled, its users won't be able to use
                  licenses that belong to the group."
          />
        ),
        accessor: "getEnabled",
      },
      {
        Header: (
          <TableHeader title="Contact" help="Main contact for this group." />
        ),
        accessor: "getContact",
      },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (groups !== null) {
      groups.map((group) => {
        values.push({
          getName: (
            <ReachLink to={"/groups/" + group.id}>{group.name}</ReachLink>
          ),
          getEnabled: group.enabled ? "Yes" : "No",
          getContact: group.contact_id,
          getEditButton: <GoToEditPageButton to={"/groups/" + group.id} />,
        });
      });
    }

    return values;
  }, [groups]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      getGroupsByAccount(workingAccount.id, pageSize * pageIndex, pageSize);
    },
    [workingAccount.id]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Groups Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Groups"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={groupsAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

export default GroupsTable;
