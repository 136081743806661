import { useState, useEffect } from "react";
import {
  HStack,
  Text,
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { Person } from "./Person";
import { PersonAPI } from "../../../api/PersonAPI";

const BG_COLOR_FORM = "#ededed";

export const AccountContact = {
  Undefined: undefined,
  Executive: "executive",
  Operations: "operations",
  It: "it",
  Billing: "billing",
};

export function ExecutiveContacts({ account_id }) {
  return (
    <AccountContactComponent
      label={"Executive contact"}
      account_id={account_id}
      contact_type={AccountContact.Executive}
      description="Executive contacts"
    />
  );
}

export function BillingContacts({ account_id }) {
  return (
    <AccountContactComponent
      label={"Billing contact"}
      account_id={account_id}
      contact_type={AccountContact.Billing}
      description="Billing contacts"
    />
  );
}

export function ItContacts({ account_id }) {
  return (
    <AccountContactComponent
      label={"It contact"}
      account_id={account_id}
      contact_type={AccountContact.It}
      description="IT contacts"
    />
  );
}

export function OperationsContacts({ account_id }) {
  return (
    <AccountContactComponent
      label={"Operations contact"}
      account_id={account_id}
      contact_type={AccountContact.Operations}
      description="Operation contacts"
    />
  );
}

function AccountContactComponent({
  label,
  account_id,
  contact_type,
  description = "",
}) {
  const [render, setRender] = useState(false);
  const [getAccountContacts, accountContacts] =
    PersonAPI.getContactsByAccountID(account_id, contact_type);

  useEffect(() => {
    getAccountContacts();
  }, [account_id, contact_type, render]);

  return (
    <VStack w="100%" bg={BG_COLOR_FORM} borderRadius="3px" align="center">
      <HStack w="100%">
        <Accordion w="100%" allowToggle>
          <AccordionItem w="100%">
            <h2>
              <AccordionButton>
                <Box w="100%" textAlign="left">
                  <HStack w="100%" align="center">
                    <BsFillPersonLinesFill color="gray.800" />
                    <Text w="100%" align="left" fontWeight="bold">
                      {label}
                    </Text>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <Text as="em">{description}</Text>
              <VStack w="100%" spacing={5}>
                {accountContacts?.map((accountContact) => {
                  return (
                    <Person
                      person_id={accountContact?.id}
                      account_id={account_id}
                      icon={null}
                    />
                  );
                })}
                <AddAccountContactPerson
                  account_id={account_id}
                  contact_type={contact_type}
                  onUpdate={() => {
                    setRender(!render);
                  }}
                />
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </HStack>
      ;
    </VStack>
  );
}

function AddAccountContactPerson({
  account_id,
  contact_type,
  onUpdate = () => {},
}) {
  const updatePersonByID = PersonAPI.updatePersonByID();
  return (
    <Person
      icon={null}
      account_id={account_id}
      add_person_label={contact_type}
      onCreatePerson={(person_id) => {
        const data = { account_contact_type: contact_type };
        updatePersonByID(person_id, data).then((response) => {
          onUpdate();
        });
      }}
    />
  );
}
