import React, { useState } from "react";
import { Flex, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { FiMenu, FiBook, FiUser, FiUsers, FiCpu } from "react-icons/fi";
import {
  TbLicense,
  TbFileReport,
  TbDental,
  TbTruckLoading,
} from "react-icons/tb";
import { FaUserTag } from "react-icons/fa";
import { IsAuth, ROLES } from "../Auth";

import NavItem from "./NavItem";

export default function Sidebar() {
  const isAuth = IsAuth();
  const [navSize, changeNavSize] = useState("large");
  const [show, setShow] = React.useState(true);
  const hasMediumScreen = useBreakpointValue({ base: false, md: true });

  const updateSideBarSize = () => {
    if (navSize == "small" && hasMediumScreen) changeNavSize("large");
    else changeNavSize("small");
    setShow(navSize !== "small");
  };

  React.useEffect(() => {
    updateSideBarSize();
  }, [hasMediumScreen]);

  return (
    <Flex
      pos="sticky"
      ml="5"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.10)"
      borderRadius="15px"
      h="85vh"
      w={navSize == "small" ? "75px" : "180px"}
      flexDir="column"
      justifyContent="space-between"
      zIndex="dropdown"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        overflowY="auto"
        alignItems={navSize == "small" ? "center" : "flex-start"}
      >
        <IconButton
          background="none"
          _hover={{ background: "none" }}
          _focus={{ outline: "none" }}
          icon={<FiMenu />}
          onClick={() => {
            updateSideBarSize();
          }}
        />
        {isAuth([ROLES.SUPER_USER]) && (
          <NavItem
            navSize={navSize}
            icon={FiBook}
            title="Accounts"
            linkTo="accounts"
          />
        )}
        {isAuth([ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]) && (
          <NavItem
            navSize={navSize}
            icon={FiUsers}
            title="Groups"
            linkTo="groups"
          />
        )}
        {isAuth([ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]) && (
          <NavItem
            navSize={navSize}
            icon={FiUser}
            title="Users"
            linkTo="users"
          />
        )}
        {isAuth([ROLES.SUPER_USER, ROLES.VISIONX_ADMIN]) && (
          <NavItem
            navSize={navSize}
            icon={FiCpu}
            title="Software"
            linkTo="softwares"
          />
        )}
        {isAuth([
          ROLES.SUPER_USER,
          ROLES.VISIONX_ADMIN,
          ROLES.ACCOUNT_ADMIN,
        ]) && (
          <NavItem
            navSize={navSize}
            icon={TbLicense}
            title="Licenses"
            linkTo="licenses"
            createSubMenu={isAuth([ROLES.SUPER_USER, ROLES.VISIONX_ADMIN])}
            showSubMenu={isAuth([
              ROLES.SUPER_USER,
              ROLES.VISIONX_ADMIN,
              ROLES.ACCOUNT_ADMIN,
            ])}
          />
        )}
        {isAuth([ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]) && (
          <NavItem
            navSize={navSize}
            icon={TbFileReport}
            title="Sessions"
            linkTo="sessions"
            createSubMenu={false}
            showSubMenu={false}
          />
        )}
        {isAuth([ROLES.USER]) && (
          <NavItem
            navSize={navSize}
            icon={TbFileReport}
            title="Sessions"
            linkTo="mysessions"
            createSubMenu={false}
            showSubMenu={false}
          />
        )}
        {isAuth([ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]) && (
          <NavItem
            navSize={navSize}
            icon={TbDental}
            title="Products"
            linkTo="products"
            createSubMenu={isAuth([
              ROLES.SUPER_USER,
              ROLES.ACCOUNT_ADMIN,
              ROLES.VISIONX_ADMIN,
            ])}
            showSubMenu={isAuth([
              ROLES.SUPER_USER,
              ROLES.VISIONX_ADMIN,
              ROLES.ACCOUNT_ADMIN,
            ])}
          />
        )}
        {isAuth([ROLES.SUPER_USER, ROLES.ACCOUNT_ADMIN]) && (
          <NavItem
            navSize={navSize}
            icon={TbTruckLoading}
            title="Productions"
            linkTo="productions"
            createSubMenu={false}
            showSubMenu={false}
          />
        )}
        {isAuth([ROLES.SUPER_USER]) && (
          <NavItem
            navSize={navSize}
            icon={FaUserTag}
            title="Data Role"
            linkTo="data_roles"
            createSubMenu={true}
            showSubMenu={true}
          />
        )}
      </Flex>
    </Flex>
  );
}
