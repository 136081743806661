import React, { useEffect, useContext } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Formik, Field } from "formik";
import {
  VStack,
  HStack,
  Spacer,
  FormControl,
  CheckboxGroup,
  Checkbox,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Center,
  List,
  ListItem,
  ListIcon,
  SimpleGrid,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { BiGroup } from "react-icons/bi";
import { AccountContext } from "../../../context/AccountContext";
import { popupInfo, GetPopup } from "../../../components/utils/StatusPopup";
import { UserAPI } from "../../../api/UserAPI";
import { GroupUserAPI } from "../../../api/GroupUserAPI";
import { EditIconButton, HelpIconButton } from "../Buttons";

function UserSelector({ label, groupInfo, help }) {
  const workingAccount = useContext(AccountContext);

  const popup = GetPopup();
  const [getUsersByGroupID, usersByGroupID, usersByGroupIDLoaded] =
    GroupUserAPI.getUsersByGroupID();

  const [getUsersByAcount, usersAmount, users] = UserAPI.usersByAccount();

  const [removeUserGroup] = GroupUserAPI.deleteUserGroup();
  const [addUserGroup] = GroupUserAPI.postUserGroup();

  const { openModal, closeModal, UserSelectorModal } = CreateModal();

  const currentUsersIDs = React.useMemo(() => {
    let userIDs = [];

    if (usersByGroupID) {
      for (let index = 0; index < usersByGroupID.length; ++index) {
        userIDs.push(usersByGroupID[index].id);
      }
    }

    return userIDs;
  }, [usersByGroupID]);

  useEffect(() => {
    getUsersByGroupID(groupInfo.id);
    getUsersByAcount(workingAccount.id, 0, usersAmount);
  }, [groupInfo, usersAmount]);

  return (
    <VStack align="left" alignItems="left" w="100%">
      <HStack w="100%">
        <BiGroup color="gray.800" />
        <Text align="left" fontWeight="bold">
          {label || "Groups"}
        </Text>
        <HelpIconButton title={label} help={help} />
        <Spacer />
        <EditIconButton onClick={openModal} />
        <UserSelectorModal
          usersInAccount={users}
          usersByGroupID={usersByGroupID}
          onNewValueConfirmed={(newUserIDs) => {
            // Removing user-group existant relations.
            const userIDsToRemove = usersByGroupID.filter(
              (userGroupID) => !newUserIDs.includes(userGroupID.id)
            );
            userIDsToRemove.forEach((oldUserID) => {
              removeUserGroup({
                group_id: groupInfo.id,
                user_id: oldUserID.id,
              }).then((response) => {
                if (response?.status == 200) {
                  getUsersByGroupID(groupInfo.id);
                  getUsersByAcount(workingAccount.id, 0, usersAmount);
                }
              });
            });
            // Adding new user-group relations.
            const userIDsToAdd = newUserIDs.filter(
              (originalGroupID) => !currentUsersIDs.includes(originalGroupID)
            );
            userIDsToAdd.forEach((newUserID) => {
              addUserGroup({
                group_id: groupInfo.id,
                user_id: newUserID,
                enabled: true,
              }).then((response) => {
                if (response?.status == 200) {
                  getUsersByGroupID(groupInfo.id);
                  getUsersByAcount(workingAccount.id, 0, usersAmount);
                }
              });
            });

            popupInfo(popup, "Trying to apply changes requested to groups");
            closeModal();
          }}
        />
      </HStack>
      <HStack w="100%">
        {usersByGroupIDLoaded ? (
          usersByGroupID.length ? (
            <List spacing={3}>
              <SimpleGrid columns={2} spacing={2}>
                {usersByGroupID.map((userInfo) => {
                  return (
                    <ListItem as={HStack} key={userInfo.id}>
                      <ListIcon as={ChevronRightIcon} color="blue.500" />
                      <Text overflow="hidden">
                        <ReachLink to={"/users/" + userInfo.id}>
                          {userInfo?.name}
                        </ReachLink>
                      </Text>
                    </ListItem>
                  );
                })}
              </SimpleGrid>
            </List>
          ) : (
            <>
              <Spacer />
              <Text> There is not information regarding users.</Text>
              <Spacer />
            </>
          )
        ) : (
          <Center w="100%">
            <VStack>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Text as="i"> Loading users...</Text>
            </VStack>
          </Center>
        )}
      </HStack>
    </VStack>
  );
}

function CreateModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const UserSelectorModal = ({
    usersInAccount,
    usersByGroupID,
    onNewValueConfirmed,
  }) => {
    let userGroupIDStr = usersByGroupID
      ? usersByGroupID.map((users) => {
          // Converting group ids integer to str
          return String(users.id);
        })
      : [];

    return (
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <VStack maxHeight="75vh" overflow="auto">
            {usersInAccount && usersByGroupID ? (
              usersInAccount.length ? (
                <Formik
                  initialValues={{
                    users: userGroupIDStr || [],
                  }}
                  onSubmit={(values, {}) => {
                    onNewValueConfirmed(
                      // Converting group ids from str to integer
                      values?.users.map((value) => {
                        return parseInt(value);
                      })
                    );
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <ModalHeader>Please select users in group:</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody overflow="clip">
                        <FormControl>
                          <Field name="users">
                            {({ field, form }) => (
                              <CheckboxGroup
                                overflow="auto"
                                defaultValue={userGroupIDStr}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                              >
                                <SimpleGrid>
                                  {usersInAccount.map((user) => (
                                    <Checkbox
                                      id={user.id}
                                      key={user.id}
                                      value={String(user.id)}
                                      margin="10px"
                                    >
                                      {user.name}
                                    </Checkbox>
                                  ))}
                                </SimpleGrid>
                              </CheckboxGroup>
                            )}
                          </Field>
                        </FormControl>
                      </ModalBody>

                      <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={onClose}>
                          Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={handleSubmit}>
                          Update
                        </Button>
                      </ModalFooter>
                    </form>
                  )}
                </Formik>
              ) : (
                <>
                  <ModalHeader> Group users update</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Center>
                      <VStack spacing={10} mb={10}>
                        <Text as="i">
                          There are not users on the current account.
                        </Text>
                        <Button colorScheme="linkedin" mr={3} onClick={onClose}>
                          <ReachLink to={"/users/new"}>Create user</ReachLink>
                        </Button>
                      </VStack>
                    </Center>
                  </ModalBody>
                </>
              )
            ) : (
              <>
                <ModalHeader> Group users update</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center>
                    <VStack>
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                      <Text as="i"> Loading users...</Text>
                    </VStack>
                  </Center>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </>
            )}
          </VStack>
        </ModalContent>
      </Modal>
    );
  };

  return { openModal: onOpen, closeModal: onClose, UserSelectorModal };
}

export default UserSelector;
