// ME endpoint
export const USER_ME_URL = "/users/me/";

// Login endpoint
export const LOGIN_URL = "/login/access-token/";

// Signup endpoint
export const SIGNUP_URL = "/users/signup/create-password/";

// Getter endpoints
export const GET_ACCOUNTS_URL = "/accounts/";
export const GET_ACCOUNT_BY_ID_URL = (account_id) => {
  return `/accounts/${account_id}/`;
};
export const GET_ACCOUNTS_NAME_BY_ACCOUNT_ID_URL = (account_id) => {
  return `/accounts/${account_id}/name/`;
};

export const GET_CONTACT_TYPE_BY_ACCOUNT_ID_URL = (account_id) => {
  return `/persons/account_contact/${account_id}/`;
};

export const GET_DATA_ROLE_URL = "/data_roles/";
export const GET_DATA_ROLE_BY_ID_URL = (data_role_id) => {
  return `/data_roles/${data_role_id}/`;
};

export const GET_ADDRESS_ME_URL = "/addresses/me/";
export const CREATE_ADDRESS_ME_URL = "/addresses/me";
export const UPDATE_ADDRESS_ME_URL = "/addresses/me";

export const GET_PERSON_ADDRESS_URL = (person_id) => {
  return `/persons/${person_id}/address/`;
};

export const GET_PERSON_BY_ID_URL = (person_id) => {
  return `/persons/${person_id}/`;
};

export const GET_PRODUCT_BY_ID_URL = (product_id) => {
  return `/products/${product_id}/`;
};

export const GET_GROUPS_URL = "/groups/";
export const GET_GROUPS_IN_ACCOUNT_URL = "/groups/account/";
export const GET_ADDRESS_BY_ACCOUNT_ID = (account_id) => {
  return `/accounts/${account_id}/address/`;
};
export const GET_ADDRESS_BY_GROUP_ID = (group_id) => {
  return `/groups/${group_id}/address/`;
};
export const GET_GROUP_BY_ID_URL = (group_id) => {
  return `/groups/${group_id}/`;
};
export const GET_USERS_URL = "/users/";
export const GET_USERS_IN_ACCOUNT_URL = "/users/account/";
export const GET_GROUP_IDS_FROM_USER_ID_URL = (user_id) => {
  return `/group_users/${user_id}/groups/id/`;
};
export const GET_USERS_BY_GROUP_ID_URL = (group_id) => {
  return `/group_users/${group_id}/users/`;
};
export const GET_USER_BY_ID_URL = (user_id) => {
  return `/users/${user_id}/`;
};
export const GET_SESSIONS_URL = "/user_sessions/";
export const GET_SESSIONS_ME_URL = "/user_sessions/me/";
export const GET_SESSIONS_BY_ACCOUNT_URL = "/user_sessions/account/";
export const GET_LICENSES_URL = "/licenses/";
export const GET_LICENSE_BY_ID_URL = (license_id) => {
  return `/licenses/${license_id}`;
};
export const GET_LICENSES_IN_ACCOUNT_URL = "/licenses/account/";
export const GET_SOFTWARES_URL = "/softwares/";
export const GET_SOFTWARE_BY_ID_URL = (software_id) => {
  return `/softwares/${software_id}`;
};
export const GET_PRODUCTS_BY_ACCOUNT_URL = "/products/account/";
export const GET_PRODUCTIONS_BY_ACCOUNT_URL = "/productions/account/";
export const GET_PERSON_ME_URL = "/persons/me/";

// Creation endpoints
export const CREATE_ACCOUNT_WITH_ADMIN_URL = "/accounts/admin/";
export const CREATE_PERSON_ADDRESS_URL = (person_id) => {
  return `/persons/${person_id}/address/`;
};
export const CREATE_GROUP_URL = "/groups/";
export const CREATE_ADDRESS_BY_ACCOUNT_ID = (account_id) => {
  return `/accounts/${account_id}/address/`;
};
export const CREATE_DATA_ROLE_URL = "/data_roles/";
export const CREATE_ADDRESS_BY_GROUP_ID = (group_id) => {
  return `/groups/${group_id}/address/`;
};
export const CREATE_GROUP_USER = "/group_users/";
export const CREATE_USER_URL = "/users/";
export const CREATE_LICENSE_URL = "/licenses/";
export const CREATE_SOFTWARE_URL = "/softwares/";
export const CREATE_PRODUCT_URL = "/products/";
export const CREATE_PERSON_URL = "/persons/";
export const CREATE_PERSON_ME_URL = "/persons/me/";

// Update endpoints
export const UPDATE_ACCOUNT_BY_ID_URL = (account_id) => {
  return `/accounts/${account_id}/`;
};
export const UPDATE_DATA_ROLE_BY_ID_URL = (data_role_id) => {
  return `/data_roles/${data_role_id}/`;
};
export const UPDATE_PERSON_ADDRESS_URL = (person_id) => {
  return `/persons/${person_id}/address/`;
};
export const UPDATE_GROUP_BY_ID_URL = (group_id) => {
  return `/groups/${group_id}/`;
};
export const UPDATE_ADDRESS_BY_ACCOUNT_ID = (account_id) => {
  return `/accounts/${account_id}/address/`;
};
export const UPDATE_ADDRESS_BY_GROUP_ID = (group_id) => {
  return `/groups/${group_id}/address/`;
};
export const UPDATE_LICENSE_BY_ID_URL = (license_id) => {
  return `/licenses/${license_id}`;
};
export const UPDATE_PERSON_BY_ID_URL = (person_id) => {
  return `/persons/${person_id}`;
};
export const UPDATE_PRODUCT_BY_ID_URL = (product_id) => {
  return `/products/${product_id}`;
};
export const UPDATE_SOFTWARE_BY_ID_URL = (software_id) => {
  return `/softwares/${software_id}`;
};
export const UPDATE_USER_BY_ID_URL = (user_id) => {
  return `/users/${user_id}/`;
};
export const UPDATE_USER_ME = "/users/me/";
export const UPDATE_PERSON_ME = "/persons/me/";

// Delete endpoints
export const DELETE_GROUP_USER = "/group_users/";

// OTHERS
export const SEND_SIGNUP_TOKEN_EMAIL = "/users/signup/send-signup-token-email/";

export const END_SESSION_BY_USER_ID = (user_id) => {
  return `/user_sessions/end/${user_id}/`;
};

export const TOKEN_LIFETIME = 1;
