import React, { useEffect } from "react";
import { Link as ReachLink } from "react-router-dom";
import {
  VStack,
  HStack,
  Spacer,
  Select,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { FaUserTag } from "react-icons/fa";
import { popupInfo, GetPopup } from "../../../components/utils/StatusPopup";
import { DataRoleAPI } from "../../../api/DataRoleAPI";
import { EditIconButton, HelpIconButton } from "../Buttons";

function DataRoleSelector({ label, userInfo, help, onUpdate }) {
  const popup = GetPopup();
  const [currentDataRole, setCurrentDataRole] = React.useState("None");
  const [getDataRoles, , dataRoles, dataRolesLoaded] =
    DataRoleAPI.getDataRoles();
  const { openModal, closeModal, DataRoleSelectorModal } =
    CreateModal(currentDataRole);

  useEffect(() => {
    getDataRoles();
  }, [userInfo]);

  useEffect(() => {
    if (dataRoles && userInfo?.data_role_id) {
      const dataRoleFound = dataRoles.findLast(
        (dataRole) => dataRole.id === userInfo?.data_role_id
      );

      setCurrentDataRole(
        dataRoleFound
          ? dataRoleFound?.name + " (Code:" + dataRoleFound?.code + ")"
          : "None"
      );
    } else {
      setCurrentDataRole("None");
    }
  }, [dataRoles, userInfo]);

  return (
    <VStack align="left" alignItems="left" w="100%">
      <HStack w="100%">
        <FaUserTag color="gray.800" />
        <Text align="left" fontWeight="bold">
          {label || "Data role"}
        </Text>
        <HelpIconButton title={label} help={help} />
        <Spacer />
        <EditIconButton onClick={openModal} />
        <DataRoleSelectorModal
          dataRoles={dataRoles}
          dataRolesLoaded={dataRolesLoaded}
          onNewValueConfirmed={(newDataRoleID) => {
            onUpdate(newDataRoleID == 0 ? null : newDataRoleID);
            popupInfo(popup, "Trying to apply changes requested to data role");
            closeModal();
          }}
        />
      </HStack>
      <HStack w="100%">
        {dataRolesLoaded ? (
          dataRoles.length && currentDataRole ? (
            <Center w="100%">
              <Text as="i" overflow="hidden">
                {currentDataRole}
              </Text>
            </Center>
          ) : (
            <>
              <Spacer />
              <Text> There is not information regarding user data role.</Text>
              <Spacer />
            </>
          )
        ) : (
          <Center w="100%">
            <VStack>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Text as="i"> Loading data roles...</Text>
            </VStack>
          </Center>
        )}
      </HStack>
    </VStack>
  );
}

function CreateModal(currentDataRole) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newDataRole, setNewDataRole] = React.useState(
    currentDataRole?.data_role_id
  );

  const DataRoleSelectorModal = ({
    dataRoles,
    dataRolesLoaded,
    onNewValueConfirmed,
  }) => {
    return (
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          {dataRoles && dataRolesLoaded ? (
            dataRoles.length ? (
              <>
                <ModalHeader>Please select user's data role:</ModalHeader>
                <ModalCloseButton />
                <ModalBody overflow="clip">
                  <Select
                    defaultValue={newDataRole || 0}
                    onChange={(event) => {
                      setNewDataRole(event?.target?.value);
                    }}
                  >
                    <option key={0} value={0}>
                      None
                    </option>
                    {dataRoles.map((dataRole) => (
                      <option key={dataRole.id} value={dataRole.id}>
                        {" "}
                        {dataRole.name}{" "}
                      </option>
                    ))}
                  </Select>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      if (newDataRole) {
                        onNewValueConfirmed(newDataRole);
                      }
                      onClose();
                    }}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </>
            ) : (
              <>
                <ModalHeader> Data roles update</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center>
                    <VStack spacing={10} mb={10}>
                      <Text as="i">There are not data roles created.</Text>
                      <Button colorScheme="linkedin" mr={3} onClick={onClose}>
                        <ReachLink to={"/data_roles/new"}>
                          Create data role
                        </ReachLink>
                      </Button>
                    </VStack>
                  </Center>
                </ModalBody>
              </>
            )
          ) : (
            <>
              <ModalHeader> Data roles update</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Center>
                  <VStack>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                    <Text as="i"> Loading data roles...</Text>
                  </VStack>
                </Center>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  };

  return { openModal: onOpen, closeModal: onClose, DataRoleSelectorModal };
}

export default DataRoleSelector;
