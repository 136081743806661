import { useState } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  VStack,
  HStack,
  Spacer,
  Center,
  Text,
} from "@chakra-ui/react";
import { HelpIconButton } from "../utils/Buttons";
import { DataRoleAPI } from "../../api/DataRoleAPI";

const DataRoleForm = () => {
  const [submittingData, setSubmittingData] = useState(false);
  const [submitDataRole] = DataRoleAPI.postDataRole();

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create Data Role
        </Heading>
      </Stack>
      <Box bg="white" w="60vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Text as="i">
          Data roles are used to define how the user is going to use the Case
          Managment System.
        </Text>
        <Formik
          initialValues={{
            dataRoleName: "",
            dataRoleCode: "",
            dataRoleDescription: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            submitDataRole({
              name: values["dataRoleName"],
              code: values["dataRoleCode"],
              description: values["dataRoleDescription"],
            }).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.dataRoleName && touched.dataRoleName}
                >
                  <HStack>
                    <FormLabel htmlFor="dataRoleName">
                      New Data Role name
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="data role name"
                      help="New data role name is case insensitve and can contain
                            intermediate whitespaces."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="dataRoleName"
                    name="dataRoleName"
                    type="dataRoleName"
                    variant="filled"
                    placeholder="e.g. technician, designer"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Data role name is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.dataRoleName}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={errors.dataRoleName && touched.dataRoleName}
                >
                  <HStack>
                    <FormLabel htmlFor="dataRoleCode">Code</FormLabel>
                    <Spacer />
                    <HelpIconButton title="code" help="Data role code." />
                  </HStack>
                  <Field
                    as={Input}
                    id="dataRoleCode"
                    name="dataRoleCode"
                    type="dataRoleCode"
                    variant="filled"
                    placeholder="e.g. 5, 2"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Data role code is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.dataRoleName}</FormErrorMessage>
                </FormControl>

                <FormControl>
                  <HStack>
                    <FormLabel htmlFor="dataRoleDescription">
                      Description
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="description"
                      help="Data role usage in CMS."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="dataRoleDescription"
                    name="dataRoleDescription"
                    type="name"
                    placeholder="Brief description for the new data role"
                    variant="filled"
                  />
                </FormControl>

                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create data role
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default DataRoleForm;
