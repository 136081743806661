import { useAxiosPost } from "../hooks/UseAxios";
import {
  popupError,
  popupSuccess,
  GetPopup,
} from "../components/utils/StatusPopup";

import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class DataRoleAPI {
  static postDataRole = () => {
    const popup = GetPopup();
    return useAxiosPost(
      C.CREATE_DATA_ROLE_URL,
      () => popupSuccess(popup, "CMS Data Role created successfully"),
      (detail) => popupError(popup, "Cannot create CMS Data Role", detail)
    );
  };

  static getDataRoles = () => {
    return APIUtils.dataByRange(
      C.GET_DATA_ROLE_URL,
      "Unable to load CMS Data Roles"
    );
  };

  static getDataRoleByID = () => {
    return APIUtils.getDataByID(
      C.GET_DATA_ROLE_BY_ID_URL,
      "Unable to get CMS Data Role"
    );
  };

  static updateDataRoleByID = () => {
    return APIUtils.updateDataByID(
      C.UPDATE_DATA_ROLE_BY_ID_URL,
      "CMS Data Role information updated",
      "Unable to update CMS Data Role information"
    );
  };
}
