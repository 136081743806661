import validator from 'validator'

export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
};

export function hasWhiteSpaces(value){
   return (/\s/).test(value);
};

export function isStrongPassword(value){
  return validator.isStrongPassword(
    value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1,
      minSymbols: 0
  })
}
