import React, { useContext } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  VStack,
  HStack,
  Spacer,
  Center,
  Text,
} from "@chakra-ui/react";
import { HelpIconButton } from "../utils/Buttons";
import { GroupAPI } from "../../api/GroupAPI";
import { AccountContext } from "../../context/AccountContext";
import { hasWhiteSpaces } from "../utils/Utils";

const GroupForm = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const workingAccount = useContext(AccountContext);
  const [submitGroup] = GroupAPI.postGroup();

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create group
        </Heading>
      </Stack>
      <Box bg="white" w="60vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />

          <Spacer />
        </HStack>
        <Text as="i">
          A group will identify one or more users that usually are related
          (because they work together in the same lab, city, etc).
        </Text>
        <Formik
          initialValues={{
            groupName: "",
            groupEnabled: true,
            accountId: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            submitGroup({
              name: values["groupName"],
              enabled: values["groupEnabled"],
              account_id: workingAccount.id,
            }).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.groupName && touched.groupName}
                >
                  <HStack>
                    <FormLabel htmlFor="groupName">New group name</FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="group name"
                      help="New group name (without whitespaces)."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="groupName"
                    name="groupName"
                    type="groupName"
                    variant="filled"
                    placeholder="New group name (without whitespaces)."
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Group name is required";
                      }
                      if (hasWhiteSpaces(value)) {
                        error = "Groups name can't have whitespaces";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.groupName}</FormErrorMessage>
                </FormControl>

                <FormControl display="flex" alignItems="center">
                  <HStack w="100%">
                    <FormLabel m={0} htmlFor="groupEnabled">
                      Group enabled
                    </FormLabel>
                    <HelpIconButton
                      title="group enabled"
                      help="If the group is disabled users won't have access to
                            its licenses."
                    />
                    <Spacer />
                    <Field id="groupEnabled" name="groupEnabled">
                      {({ field, form }) => (
                        <Switch
                          size="md"
                          defaultChecked
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.checked);
                          }}
                        />
                      )}
                    </Field>
                  </HStack>
                </FormControl>

                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create group
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default GroupForm;
