import React, { useState, useEffect } from "react";
import { useTable, useFilters, useExpanded, usePagination } from "react-table";
import useMap from "../../hooks/UseMap";
import {
  Button,
  Spacer,
  HStack,
  VStack,
  StackDivider,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  InputGroup,
  InputLeftElement,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  Text,
} from "@chakra-ui/react";
import { HelpIconButton } from "../utils/Buttons";
import { GoSearch } from "react-icons/go";

export function TableHeader({ title, help }) {
  return (
    <HStack align="center" zIndex="dropdown">
      <Text>{title}</Text>
      <HelpIconButton title={title} help={help} />
    </HStack>
  );
}

export function FilterTable({
  tableCaption,
  columns,
  data,
  fetchData,
  dataAmount,
  resetTableDependencies = [],
}) {
  const [columnToFilter, setColumnToFilter] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [pagesAmount, setPagesAmount] = useState(Math.ceil(dataAmount / 5));
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      pageCount: pagesAmount,
      manualPagination: true,
      autoResetPage: false,
    },
    useFilters,
    useExpanded,
    usePagination
  );
  const {
    get: getFilterValue,
    set: setFilterValue,
    reset: resetFilters,
    values: searchFilters,
  } = useMap(getMapWithFilter(headerGroups));

  useEffect(() => {
    fetchData && fetchData(pageSize, pageIndex);
  }, [fetchData, pageIndex, pageSize]);

  // If the data changes and we have filters defined we need to filter again.
  useEffect(() => {
    updateFilters(getFilterValue, headerGroups);
  }, [data]);

  // If the data amount or the page size changes we need to update the pages amount.
  useEffect(() => {
    setPagesAmount(Math.ceil(dataAmount / pageSize));
  }, [dataAmount, pageSize]);

  // If a reset table depency is updated we need to go to page zero.
  useEffect(() => {
    gotoPage(0);
  }, resetTableDependencies);

  return (
    <VStack align="stretch" spacing={2} padding={2} h="auto" w="auto">
      <HStack h="auto" w="auto">
        <InputGroup width="40%">
          <InputLeftElement
            pointerEvents="none"
            children={<GoSearch color="gray.300" />}
          />
          <Input
            placeholder="Enter text to search..."
            onChange={(event) => {
              setFilterInput(event.target.value.trim());
            }}
          />
        </InputGroup>
        <Select
          width="30%"
          placeholder="Select a column..."
          onChange={(event) => {
            setColumnToFilter(event.target.value);
          }}
        >
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) => (
              <option
                key={column.render("Header")}
                value={column.render("Header")}
                hidden={
                  typeof column["Header"] !== "string" || !column.canFilter
                }
              >
                {column.render("Header")}
              </option>
            ))
          )}
        </Select>
        <Button
          colorScheme="blue"
          width="15%"
          disabled={
            columnToFilter == null ||
            columnToFilter.length == 0 ||
            filterInput == null ||
            filterInput.length == 0
          }
          onClick={async () => {
            const column = getFilterValue(columnToFilter);
            column.filter(filterInput);
            setFilterValue(columnToFilter, {
              input: filterInput,
              filter: column.filter,
            });
          }}
        >
          Add filter
        </Button>
        <Button
          colorScheme="teal"
          width="15%"
          onClick={async () => {
            for (const key in searchFilters) {
              const column = getFilterValue(key);
              column.filter("");
              setFilterValue(key, { input: "", filter: column.filter });
            }
          }}
        >
          Clear filters
        </Button>
      </HStack>

      {renderTags(searchFilters, getFilterValue, setFilterValue)}
      <StackDivider />
      <TableContainer variant="striped">
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} isNumeric={column.isNumeric}>
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} _hover={{ bg: "blue.50" }}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {renderPaginationElements(
          pageIndex,
          pageSize,
          pageCount,
          pageOptions,
          canPreviousPage,
          canNextPage,
          { gotoPage, previousPage, nextPage, setPageSize }
        )}
      </TableContainer>
      <HStack>
        <Spacer />
        <Text fontSize="12px" color="grey" fontWeight="bold">
          {tableCaption}
        </Text>
        <Spacer />
      </HStack>
    </VStack>
  );
}

function renderPaginationElements(
  pageIndex,
  pageSize,
  pageCount,
  pageOptions,
  canPreviousPage,
  canNextPage,
  pageActions
) {
  const availablePageSizes = [5, 10, 25, 50];
  const { gotoPage, previousPage, nextPage, setPageSize } = pageActions;

  const pageNavigation =
    pageCount > 1 ? (
      <HStack mt={2} w="90%">
        <Spacer />
        <Button
          size="sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </Button>
        <Button
          size="sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </Button>
        <Text fontSize="12px">Page</Text>
        <Text fontSize="14px" fontWeight="bold">
          {pageIndex + 1} of {pageOptions.length}
        </Text>
        <Button size="sm" onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
        <Button
          size="sm"
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>
        <Spacer />
      </HStack>
    ) : (
      <></>
    );

  return (
    <HStack w="100%">
      {pageNavigation}
      <Spacer />
      <HStack mt={2} w="130px" justify="right" alignItems="right" align="right">
        <Text size="sm" fontWeight="bold">
          Show:
        </Text>
        <Select
          size="xs"
          value={pageSize}
          variant="filled"
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {availablePageSizes.map((sizeOptions) => (
            <option key={sizeOptions} value={sizeOptions}>
              {sizeOptions}
            </option>
          ))}
        </Select>
      </HStack>
    </HStack>
  );
}

function renderTags(searchFilters, getFilterValue, setFilterValue) {
  const finalRender = [];
  const colors = [
    "green",
    "cyan",
    "yellow",
    "orange",
    "gray",
    "purple",
    "pink",
  ];

  for (const key in searchFilters) {
    const column = getFilterValue(key);

    if (column.input !== undefined && column.input?.length !== 0) {
      const colorIndex = key.length % colors.length;
      finalRender.push(
        <WrapItem>
          <Tag
            size="lg"
            borderRadius="full"
            variant="solid"
            colorScheme={colors[colorIndex]}
          >
            <TagLabel>{"[" + key + '] : "' + column.input + '"'}</TagLabel>
            <TagCloseButton
              onClick={async (event) => {
                column.filter("");
                setFilterValue(key, { input: "", filter: column.filter });
              }}
            />
          </Tag>
        </WrapItem>
      );
    }
  }
  return <Wrap spacing={2}> {finalRender} </Wrap>;
}

function getMapWithFilter(headerGroups) {
  const mapFilter = {};
  headerGroups.map((headerGroup) => {
    headerGroup.headers.map((column) => {
      const key = column["Header"];
      if (typeof key == "string" && column.canFilter) {
        const { setFilter } = column;
        mapFilter[key] = { input: "", filter: setFilter };
      }
    });
  });

  return mapFilter;
}

function updateFilters(getFilterValue, headerGroups) {
  headerGroups.map((headerGroup) => {
    headerGroup.headers.map((newColumn) => {
      const key = newColumn["Header"];
      if (typeof key == "string" && newColumn.canFilter) {
        const originalColumn = getFilterValue(key);
        if (originalColumn?.input !== "") {
          originalColumn.filter(originalColumn.input);
        }
      }
    });
  });
}
