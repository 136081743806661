import React from "react";
import {
  Flex,
  Text,
  Icon,
  Link,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { BsTable } from "react-icons/bs";
import { Link as ReachLink } from "react-router-dom";

export default function navSize({
  icon,
  title,
  active,
  navSize,
  linkTo,
  createSubMenu = true,
  showSubMenu = true,
}) {
  return (
    <Flex
      mt={5}
      flexDir="column"
      w="100%"
      alignItems={navSize == "small" ? "center" : "flex-start"}
    >
      {showSubMenu || createSubMenu
        ? createMenuWithSubMenu({
            icon,
            title,
            active,
            navSize,
            linkTo,
            createSubMenu,
            showSubMenu,
          })
        : createSimpleMenu({ icon, title, active, navSize, linkTo })}
    </Flex>
  );
}

const createMenuWithSubMenu = ({
  icon,
  title,
  active,
  navSize,
  linkTo,
  createSubMenu,
  showSubMenu,
}) => {
  return (
    <Menu padding="10px">
      <MenuButton p={1}>
        <Flex>
          <Icon
            as={icon}
            fontSize="xl"
            color={active ? "#82AAAD" : "gray.500"}
          />
          <Text ml={5} display={navSize == "small" ? "none" : "flex-start"}>
            {title}
          </Text>
        </Flex>
      </MenuButton>
      <MenuList minW="150px">
        {createSubMenu && (
          <ReachLink to={linkTo == null ? "/home" : linkTo + "/new"}>
            <Text backgroundColor={active && "#AEC8CA"} w="100%">
              <MenuItem icon={<AddIcon />} w="100%">
                Create
              </MenuItem>
            </Text>
          </ReachLink>
        )}
        {showSubMenu && (
          <ReachLink to={linkTo == null ? "/home" : linkTo + "/show"}>
            <Text backgroundColor={active && "#AEC8CA"} w="100%">
              <MenuItem icon={<BsTable />} w="100%">
                Show table
              </MenuItem>
            </Text>
          </ReachLink>
        )}
      </MenuList>
    </Menu>
  );
};

const createSimpleMenu = ({ icon, title, active, navSize, linkTo }) => {
  return (
    <Menu placement="right">
      <ReachLink to={linkTo == null ? "/home" : linkTo}>
        <Text backgroundColor={active && "#AEC8CA"} w={"50%"} m={0}>
          <MenuButton w="100%" p={2}>
            <Flex>
              <Icon
                as={icon}
                fontSize="xl"
                color={active ? "#82AAAD" : "gray.500"}
              />
              <Text ml={5} display={navSize == "small" ? "none" : "flex-start"}>
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Text>
      </ReachLink>
    </Menu>
  );
};
