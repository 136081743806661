import { useAxiosPost, useAxiosDelete } from "../hooks/UseAxios";
import * as C from "../config/Constants";
import { APIUtils } from "./Utils";

export class GroupUserAPI {
  static getGroupsFromUserID = () => {
    return APIUtils.getDataByID(
      C.GET_GROUP_IDS_FROM_USER_ID_URL,
      "Unable to get user's groups information"
    );
  };

  static getUsersByGroupID = () => {
    return APIUtils.getDataByID(
      C.GET_USERS_BY_GROUP_ID_URL,
      "Unable to get users from requested group"
    );
  };

  static postUserGroup = () => {
    return useAxiosPost(C.CREATE_GROUP_USER, null, null);
  };

  static deleteUserGroup = () => {
    return useAxiosDelete(C.DELETE_GROUP_USER, null, null);
  };
}
