import React, { useEffect } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Formik, Field } from "formik";
import {
  VStack,
  HStack,
  Spacer,
  FormControl,
  CheckboxGroup,
  Checkbox,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Center,
  List,
  ListItem,
  ListIcon,
  SimpleGrid,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { BiGroup } from "react-icons/bi";
import { popupInfo, GetPopup } from "../../../components/utils/StatusPopup";
import { GroupAPI } from "../../../api/GroupAPI";
import { GroupUserAPI } from "../../../api/GroupUserAPI";
import { EditIconButton, HelpIconButton } from "../Buttons";

function GroupSelector({ label, userInfo, help }) {
  const popup = GetPopup();
  const [getGroupsInAccount, , groupsInAccount, groupsInAccountLoaded] =
    GroupAPI.groupsByAccount();
  const [getGroupIDs, userGroupIDs, userGroupIDsLoaded] =
    GroupUserAPI.getGroupsFromUserID();

  const [removeUserGroup] = GroupUserAPI.deleteUserGroup();
  const [addUserGroup] = GroupUserAPI.postUserGroup();

  const { openModal, closeModal, GroupSelectorModal } = CreateModal(
    userInfo.name
  );

  useEffect(() => {
    getGroupsInAccount(userInfo.account_id);
    getGroupIDs(userInfo.id);
  }, [userInfo.account_id, userInfo.id]);

  return (
    <VStack align="left" alignItems="left" w="100%">
      <HStack w="100%">
        <BiGroup color="gray.800" />
        <Text align="left" fontWeight="bold">
          {label || "Groups"}
        </Text>
        <HelpIconButton title={label} help={help} />
        <Spacer />
        <EditIconButton onClick={openModal} />
        <GroupSelectorModal
          groupsInAccount={groupsInAccount}
          userGroupIDs={userGroupIDs}
          groupsInAccountLoaded={groupsInAccountLoaded}
          onNewValueConfirmed={(newGroupIDs) => {
            // Removing user-group existant relations.
            const groupIDsToRemove = userGroupIDs.filter(
              (userGroupID) => !newGroupIDs.includes(userGroupID)
            );
            groupIDsToRemove.forEach((oldGroupID) => {
              removeUserGroup({
                user_id: userInfo.id,
                group_id: oldGroupID,
              }).then((response) => {
                if (response?.status == 200) {
                  getGroupsInAccount(userInfo?.account_id);
                  getGroupIDs(userInfo?.id);
                }
              });
            });

            // Adding new user-group relations.
            const groupIDsToAdd = newGroupIDs.filter(
              (originalGroupID) => !userGroupIDs.includes(originalGroupID)
            );
            groupIDsToAdd.forEach((newGroupID) => {
              addUserGroup({
                user_id: userInfo.id,
                group_id: newGroupID,
                enabled: true,
              }).then((response) => {
                if (response?.status == 200) {
                  getGroupsInAccount(userInfo?.account_id);
                  getGroupIDs(userInfo?.id);
                }
              });
            });

            popupInfo(popup, "Trying to apply changes requested to groups");

            closeModal();
          }}
        />
      </HStack>
      <HStack w="100%">
        {userGroupIDsLoaded && groupsInAccountLoaded ? (
          userGroupIDs.length ? (
            <List spacing={3}>
              <SimpleGrid columns={2} spacing={2}>
                {userGroupIDs.map((groupID) => {
                  let groupInfo = groupsInAccount.find(
                    (group) => group.id === groupID
                  );
                  return (
                    <ListItem as={HStack} key={groupID}>
                      <ListIcon as={ChevronRightIcon} color="blue.500" />
                      <Text overflow="hidden">
                        <ReachLink to={"/groups/" + groupInfo.id}>
                          {groupInfo?.name}
                        </ReachLink>
                      </Text>
                    </ListItem>
                  );
                })}
              </SimpleGrid>
            </List>
          ) : (
            <>
              <Spacer />
              <Text> There is not information regarding user groups.</Text>
              <Spacer />
            </>
          )
        ) : (
          <Center w="100%">
            <VStack>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Text as="i"> Loading groups...</Text>
            </VStack>
          </Center>
        )}
      </HStack>
    </VStack>
  );
}

function CreateModal(userName) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const GroupSelectorModal = ({
    groupsInAccount,
    userGroupIDs,
    groupsInAccountLoaded,
    onNewValueConfirmed,
  }) => {
    let userGroupIDStr = userGroupIDs
      ? userGroupIDs.map((groupID) => {
          // Converting group ids integer to str
          return String(groupID);
        })
      : [];

    return (
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          {groupsInAccount && groupsInAccountLoaded ? (
            groupsInAccount.length ? (
              <Formik
                initialValues={{
                  groups: userGroupIDStr || [],
                }}
                onSubmit={(values, {}) => {
                  onNewValueConfirmed(
                    // Converting group ids from str to integer
                    values?.groups.map((value) => {
                      return parseInt(value);
                    })
                  );
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <ModalHeader>Please select user's groups:</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflow="clip">
                      <FormControl>
                        <Field name="groups">
                          {({ field, form }) => (
                            <CheckboxGroup
                              defaultValue={userGroupIDStr}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                            >
                              <SimpleGrid>
                                {groupsInAccount.map((group) => (
                                  <Checkbox
                                    id={group.id}
                                    key={group.id}
                                    value={String(group.id)}
                                    margin="10px"
                                  >
                                    {group.name}
                                  </Checkbox>
                                ))}
                              </SimpleGrid>
                            </CheckboxGroup>
                          )}
                        </Field>
                      </FormControl>
                    </ModalBody>

                    <ModalFooter>
                      <Button colorScheme="red" mr={3} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button colorScheme="blue" onClick={handleSubmit}>
                        Update
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Formik>
            ) : (
              <>
                <ModalHeader> Groups update</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center>
                    <VStack spacing={10} mb={10}>
                      <Text as="i">
                        There are not groups created for the current account.
                      </Text>
                      <Button colorScheme="linkedin" mr={3} onClick={onClose}>
                        <ReachLink to={"/groups/new"}>Create group</ReachLink>
                      </Button>
                    </VStack>
                  </Center>
                </ModalBody>
              </>
            )
          ) : (
            <>
              <ModalHeader> Groups update</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Center>
                  <VStack>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                    <Text as="i"> Loading groups...</Text>
                  </VStack>
                </Center>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  };

  return { openModal: onOpen, closeModal: onClose, GroupSelectorModal };
}

export default GroupSelector;
