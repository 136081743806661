import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { AccountProvider } from "./context/AccountContext";
import { ServerStateProvider } from "./context/ServerStateContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <ServerStateProvider>
    <AuthProvider>
      <UserProvider>
        <AccountProvider>
          <App />
        </AccountProvider>
      </UserProvider>
    </AuthProvider>
  </ServerStateProvider>
);
