import React, { createContext, useEffect, useContext, useState } from "react";
import { AccountAPI } from "../api/AccountAPI";
import { Select, Text, Spinner } from "@chakra-ui/react";
import { IsAuth, ROLES } from "../components/Auth";
import { AuthContext } from "./AuthContext";
import { UserContext } from "./UserContext";

export const AccountContext = createContext();

export const AccountProvider = (props) => {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const [workingAccountId, setWorkingAccountId] = useState(
    localStorage.getItem("workingAccountId")
  );
  const [workingAccountName, setWorkingAccountName] = useState(
    localStorage.getItem("workingAccountName")
  );
  const [workingAccountloaded, setworkingAccountLoaded] = useState(
    localStorage.getItem("workingAccountLoaded")
  );
  const refreshWorkingAccount = () => {
    setworkingAccountLoaded(false);
  };

  useEffect(() => {
    const fetchUser = () => {
      if (!workingAccountId) {
        setWorkingAccountId(user.account);
        localStorage.setItem("workingAccountId", user.account);
      }
      AccountAPI.getAccountName(
        user.account,
        auth.token,
        setWorkingAccountName
      );
      localStorage.setItem("workingAccountName", workingAccountName);
      setworkingAccountLoaded(true);
    };
    fetchUser();
  }, [user.account, workingAccountloaded]);

  return (
    <AccountContext.Provider
      value={{
        id: workingAccountId,
        name: workingAccountName,
        setId: setWorkingAccountId,
        setName: setWorkingAccountName,
        loaded: workingAccountloaded,
        refresh: refreshWorkingAccount,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export const AccountContextSelect = () => {
  const workingAccount = useContext(AccountContext);
  const isAuth = IsAuth();
  const [getAccounts, accountsAmount, accounts] = AccountAPI.getAccounts();

  useEffect(() => {
    getAccounts();
  }, [workingAccount.loaded]);

  return isAuth([ROLES.SUPER_USER, ROLES.VISIONX_ADMIN]) ? (
    accounts ? (
      <Select
        w="100%"
        id="accountId"
        name="accountId"
        type="accountId"
        onChange={(e) => workingAccount.setId(e.target.value)}
        borderColor="none"
        color="white"
        _focus={{ background: "none" }}
      >
        {accountsAmount &&
          accounts.map((account) => (
            <option
              key={account.id}
              value={account.id}
              style={{ color: "black" }}
            >
              {" "}
              {account.name}{" "}
            </option>
          ))}
      </Select>
    ) : (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        label="Loading"
      />
    )
  ) : (
    <></>
  );
};
