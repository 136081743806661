import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { MdAlternateEmail } from "react-icons/md";
import { GoBackButton } from "../utils/Buttons";
import { UserAPI } from "../../api/UserAPI";
import GenericEditableInput from "../utils/editable/Generic";
import DataRoleSelector from "../utils/editable/DataRoleSelector";
import EditableSwitch from "../utils/editable/EditableSwitch";
import { Person } from "../utils/editable/Person";
import GroupSelector from "../utils/editable/GroupSelector";

const UserInfo = () => {
  const params = useParams();
  const userID = params.userID;
  const [submittingData, setSubmittingData] = React.useState(false);
  const [getUserInfo, userInfo, userInfoLoaded] = UserAPI.getUserByID();
  const updateUserByID = UserAPI.updateUserByID();
  const sendSignupTokenEmail = UserAPI.resetPasswordUser(userID);

  useEffect(() => {
    getUserInfo(userID);
  }, [userID]);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {(userInfo?.role?.name || "User") + ": " + (userInfo?.name || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {userInfoLoaded ? (
              <UserFields
                userInfo={userInfo}
                getUserInfo={getUserInfo}
                updateUserByID={updateUserByID}
                submittingData={submittingData}
                setSubmittingData={setSubmittingData}
                sendSignupTokenEmail={sendSignupTokenEmail}
              />
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function UserFields({
  userInfo,
  updateUserByID,
  getUserInfo,
  submittingData,
  setSubmittingData,
  sendSignupTokenEmail,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<FiUser color="gray.800" />}
        value={userInfo?.name}
        help="Unique user name. It will be used to log in to the system."
        onUpdate={(new_name) => {
          updateUserByID(userInfo.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Email"
        icon={<MdAlternateEmail color="gray.800" />}
        value={userInfo?.email}
        help="User email, required to set or recovery passwords."
        onUpdate={(new_email) => {
          updateUserByID(userInfo.id, {
            email: new_email,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <Person
        label="Personal info"
        account_id={userInfo?.account_id}
        person_id={userInfo?.person_id}
        help="Personal information for this user. This field is optional."
        onCreatePerson={(new_person_id) => {
          updateUserByID(userInfo.id, {
            person_id: new_person_id,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <GroupSelector
        label={"User groups"}
        userInfo={userInfo}
        help="List of groups the user belongs to."
      />
      <DataRoleSelector
        label={"Data role"}
        userInfo={userInfo}
        help="Current user data role."
        onUpdate={(new_data_role_id) => {
          updateUserByID(userInfo.id, {
            data_role_id: new_data_role_id,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <EditableSwitch
        label="User Enabled"
        value={userInfo?.enabled}
        help="If a user is disabled he/she can not log in to the web app
              or request sessions."
        onUpdate={(new_enabled) => {
          updateUserByID(userInfo.id, {
            enabled: new_enabled,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getUserInfo(userInfo.id);
            }
          });
        }}
      />
      <>
        <Button
          isLoading={submittingData}
          loadingText="Sending email"
          colorScheme="red"
          disabled={submittingData}
          onClick={onOpen}
        >
          Reset password
        </Button>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Reset {'"' + userInfo.name + '"'} password
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? A reset password email will be sent to{" "}
                {userInfo.email}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setSubmittingData(true);
                    sendSignupTokenEmail().then((response) => {
                      setSubmittingData(false);
                    });
                    onClose();
                  }}
                  ml={3}
                >
                  Reset
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    </VStack>
  );
}

export default UserInfo;
