import React, { useEffect, useContext } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  VStack,
  HStack,
  Spacer,
  Center,
  Text,
  Select,
} from "@chakra-ui/react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import * as C from "../../config/Constants";
import { hasWhiteSpaces, isValidEmail } from "../utils/Utils";
import { HelpIconButton } from "../utils/Buttons";
import { UserAPI } from "../../api/UserAPI";
import { GroupAPI } from "../../api/GroupAPI";
import { DataRoleAPI } from "../../api/DataRoleAPI";
import { AccountContext } from "../../context/AccountContext";

const UserWithGroupForm = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const workingAccount = useContext(AccountContext);
  const [getGroupsInAccount, , groupsInAccount, groupsInAccountLoaded] =
    GroupAPI.groupsByAccount();
  const [getDataRoles, , dataRoles, dataRolesLoaded] =
    DataRoleAPI.getDataRoles();
  /*TODO The setUserEnabled state could be handled with Fields props like in LicenseForm. This should be changed when API is refactored*/
  const [setUserEnabled, setSendEmail, submitUser] = UserAPI.postUsers();

  useEffect(() => {
    getDataRoles();
    getGroupsInAccount(workingAccount.id);
  }, [workingAccount.id]);

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create regular user
        </Heading>
      </Stack>

      <Box bg="white" w="60vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Text as="i">
          A user represents a person who can request sessions to later be used
          to create Production items.
        </Text>
        <Formik
          initialValues={{
            userName: "",
            userEmail: "",
            userEnabled: true,
            userDataRole: null,
            sendEmail: true,
            tokenLifetime: C.TOKEN_LIFETIME,
          }}
          onSubmit={async (values, { resetForm }) => {
            submitUser(values).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.userName && touched.userName}
                >
                  <HStack>
                    <FormLabel htmlFor="userName">New user name</FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="user name"
                      help="New user name for this account without whitespaces."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="userName"
                    name="userName"
                    type="userName"
                    variant="filled"
                    placeholder="Name without whitespaces"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "User name is required";
                      }
                      if (hasWhiteSpaces(value)) {
                        error = "User name can't have whitespaces";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.userName}</FormErrorMessage>
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <HStack w="100%">
                    <FormLabel m={0} htmlFor="userEnabled">
                      User enabled
                    </FormLabel>
                    <HelpIconButton
                      title="user enabled"
                      help="If the user is disabled it can not use the system
                            in anyway (e.g. login, create sessions, etc)."
                    />
                    <Spacer />
                    <Switch
                      id="userEnabled"
                      name="userEnabled"
                      size="md"
                      defaultChecked
                      onChange={(e) => {
                        setUserEnabled(e.target.checked);
                      }}
                    />
                  </HStack>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={errors.userEmail && touched.userEmail}
                >
                  <HStack>
                    <FormLabel htmlFor="userEmail">New user email</FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="user email"
                      help="Will be used to set the user's password."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="userEmail"
                    name="userEmail"
                    type="userEmail"
                    placeholder="e.g. user@email.com"
                    variant="filled"
                    validate={(value) => {
                      if (!value) {
                        return "User email is required";
                      }
                      if (!isValidEmail(value)) {
                        return "User email is not valid";
                      }
                      return null;
                    }}
                  />
                  <FormErrorMessage>{errors.userEmail}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <HStack>
                    <FormLabel htmlFor="userDataRole">
                      New user data role
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="user data role"
                      help="Represents dat role in Case Managment System."
                    />
                  </HStack>
                  <Field
                    as={Select}
                    id="userDataRole"
                    name="userDataRole"
                    type="userDataRole"
                    variant="filled"
                    defaultValue={0}
                  >
                    <option key={0} value={null}>
                      None
                    </option>
                    {dataRoles?.map((dataRole) => (
                      <option key={dataRole.id} value={dataRole.id}>
                        {" "}
                        {dataRole.name}{" "}
                      </option>
                    ))}
                  </Field>
                </FormControl>
                <FormControl hidden={true} display="flex" alignItems="center">
                  <FormLabel htmlFor="enabled" mb="0">
                    Send email to set password
                  </FormLabel>
                  <Switch
                    id="sendEmail"
                    name="sendEmail"
                    size="md"
                    defaultChecked
                    onChange={(e) => {
                      setSendEmail(e.target.checked);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <HStack>
                    <FormLabel htmlFor="tokenLifetime">
                      Token duration (hours)
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="token"
                      help="Time in hours that can be used to set the user's
                            password."
                    />
                  </HStack>
                  <Field name="tokenLifetime">
                    {({ field, form }) => (
                      <NumberInput
                        defaultValue={1}
                        min={0}
                        id="tokenLifetime"
                        {...field}
                        onChange={(val) => form.setFieldValue(field.name, val)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  </Field>
                </FormControl>
                {groupsInAccount && groupsInAccountLoaded ? (
                  <FormControl>
                    <HStack>
                      <FormLabel htmlFor="userGroups">Select groups</FormLabel>
                      <Spacer />
                      <HelpIconButton
                        title="groups"
                        help="Available groups for this account. A user can
                              belong to any amount of groups."
                      />
                    </HStack>
                    <Field name="userGroups">
                      {({ field, form }) => (
                        <CheckboxGroup
                          defaultValue={[]}
                          onChange={(value) => {
                            form.setFieldValue(field.name, value);
                          }}
                        >
                          {groupsInAccount.length ? (
                            groupsInAccount.map((group) => (
                              <Checkbox
                                id={group.id}
                                key={group.id}
                                value={String(group.id)}
                                margin="10px"
                              >
                                {" "}
                                {group.name}{" "}
                              </Checkbox>
                            ))
                          ) : (
                            <Text as="i">There are not groups available</Text>
                          )}
                        </CheckboxGroup>
                      )}
                    </Field>
                  </FormControl>
                ) : (
                  <></>
                )}

                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create user
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default UserWithGroupForm;
