import { useNavigate } from "react-router-dom";
import * as React from "react";
import { ChevronLeftIcon, EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverContent,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

export const GoBackButton = (props) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const hasMediumScreen = useBreakpointValue({ base: false, md: true });
  return (
    <Button
      type="submit"
      leftIcon={<ChevronLeftIcon />}
      colorScheme="blue"
      onClick={goBack}
      align="center"
      {...props}
    >
      {hasMediumScreen ? "Go back" : ""}
    </Button>
  );
};

export const EditIconButton = (props) => {
  return (
    <IconButton
      colorScheme="blue"
      aria-label="Edit"
      size="sm"
      _focus={{ outline: "none" }}
      icon={<EditIcon />}
      {...props}
    />
  );
};

export const EditButton = (props) => {
  const navigate = useNavigate();
  return (
    <Button rightIcon={<EditIcon />} colorScheme="linkedin" {...props}>
      Edit
    </Button>
  );
};

export const GoToEditPageButton = ({ to, props }) => {
  const navigate = useNavigate();
  return (
    <Button
      rightIcon={<EditIcon />}
      colorScheme="linkedin"
      variant="outline"
      onClick={() => {
        navigate(to);
      }}
      {...props}
    >
      Edit
    </Button>
  );
};

export const HelpIconButton = ({ title, help }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="Help button"
          variant="ghost"
          icon={<InfoOutlineIcon zIndex={0} />}
          _hover={{ background: "none" }}
          _expanded={{ background: "none" }}
          _focus={{ background: "none" }}
        />
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text fontWeight="bold">About {title}</Text>
        </PopoverHeader>
        <PopoverBody
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {help}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
