import React from "react";
import { Link } from "react-router-dom";
import { Heading, HStack, Image, Hide } from "@chakra-ui/react";
import VisionX_logo_dark from "../../images/VisionX_logo_dark.png";
import VisionX_logo_light from "../../images/VisionX_logo_light.png";

export function DarkHomeLogo({ size }) {
  return (
    <Link to="/">
      <HStack>
        <Image boxSize={size} src={VisionX_logo_dark} />
        <Hide below="md">
          <Heading
            as="h1"
            bgGradient="linear(to-l, grey, black)"
            bgClip="text"
            fontSize={size}
            fontWeight="extrabold"
          >
            VisionX
          </Heading>
        </Hide>
      </HStack>
    </Link>
  );
}

export default function LightHomeLogo({ size }) {
  return (
    <Link to="/">
      <HStack>
        <Image boxSize={size} src={VisionX_logo_light} />
        <Hide below="md">
          <Heading
            as="h1"
            bgGradient="linear(to-l, white, grey )"
            bgClip="text"
            fontSize={size}
            fontWeight="extrabold"
          >
            VisionX
          </Heading>
        </Hide>
      </HStack>
    </Link>
  );
}
