import React from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  VStack,
  HStack,
  Spacer,
  Center,
  Text,
} from "@chakra-ui/react";
import { HelpIconButton } from "../utils/Buttons";
import { SoftwareAPI } from "../../api/SoftwareAPI";

const SoftwareForm = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const [submitSoftware] = SoftwareAPI.postSoftware();

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create software
        </Heading>
      </Stack>

      <Box bg="white" w="60vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Text as="i">
          It is used to identify an executable provided to the users of the
          account. A Software will be related to one or more Licenses.
        </Text>
        <Formik
          initialValues={{
            softwareName: "",
            softwareVersion: "",
            softwareDescription: "",
            softwareInstallPath: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            submitSoftware({
              name: values["softwareName"],
              version: values["softwareVersion"],
              description: values["softwareDescription"],
              install_path: values["softwareInstallPath"],
            }).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.softwareName && touched.softwareName}
                >
                  <HStack>
                    <FormLabel htmlFor="softwareName">Name</FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="software name"
                      help="New software name is case insensitve and can
                            contain intermediate whitespaces."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="softwareName"
                    name="softwareName"
                    type="softwareName"
                    variant="filled"
                    placeholder="e.g. MyApp, VisionXApp, DentalSoftware"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Software name is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.softwareName}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.softwareVersion && touched.softwareVersion}
                >
                  <HStack>
                    <FormLabel htmlFor="softwareVersion">Version</FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="version"
                      help="Version for this new software (e.g. v1,
                            alpha, first-version)"
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="softwareVersion"
                    name="softwareVersion"
                    type="softwareVersion"
                    variant="filled"
                    placeholder="e.g. v1, alpha, first-version"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Software version is required";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.softwareVersion}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <HStack>
                    <FormLabel htmlFor="softwareDescription">
                      Description
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="description"
                      help="Software features."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="softwareDescription"
                    name="softwareDescription"
                    type="name"
                    placeholder="Brief description of the software."
                    variant="filled"
                  />
                </FormControl>
                <FormControl>
                  <HStack>
                    <FormLabel htmlFor="softwareInstallPath">
                      Installation Path
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="install path"
                      help="Path in your Operating System where the software will be installed."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="softwareInstallPath"
                    name="softwareInstallPath"
                    type="name"
                    placeholder="e.g. c:/path, /home/user/path"
                    variant="filled"
                  />
                </FormControl>
                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create software
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default SoftwareForm;
