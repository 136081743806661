import {
  VStack,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  useDisclosure,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { EditIconButton, HelpIconButton } from "../Buttons";

export default function GenericEditableInput({
  label,
  icon,
  value,
  onUpdate,
  help = "",
  placeholder = "",
  type = "text",
}) {
  const { openModal, closeModal, GenericModal } = CreateModal(
    label,
    help,
    placeholder,
    type
  );
  return (
    <VStack w="100%" align="left">
      <HStack>
        {icon}
        <Text align="left" fontWeight="bold">
          {label}
        </Text>
        <Spacer />
        <EditIconButton onClick={openModal} />
        <GenericModal
          value={value}
          onNewValueConfirmed={(new_value) => {
            onUpdate(new_value);
            closeModal();
          }}
        />
      </HStack>

      <InputGroup>
        <Input
          type={type}
          value={value || ""}
          placeholder={placeholder}
          isReadOnly
          _hover={{ background: "none" }}
          _focus={{ outline: "none" }}
        />
        {help ? (
          <InputRightElement
            children={<HelpIconButton title={label} help={help} />}
          />
        ) : (
          <></>
        )}
      </InputGroup>
    </VStack>
  );
}

function CreateModal(label, help, placeholder, type) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const GenericModal = ({ value, onNewValueConfirmed }) => {
    return (
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              key: value || "",
            }}
            onSubmit={(form) => {
              onNewValueConfirmed(form?.key);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader> Update {label}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {help ? (
                    <Text fontWeight="italic" mb={5}>
                      {help}
                    </Text>
                  ) : (
                    <></>
                  )}

                  <Field name="key">
                    {({ field }) => (
                      <FormControl id="key">
                        <Input
                          {...field}
                          type={type}
                          _hover={{ background: "none" }}
                          _focus={{ outline: "none" }}
                          placeholder={placeholder}
                        />
                      </FormControl>
                    )}
                  </Field>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="blue" onClick={handleSubmit}>
                    Update
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    );
  };

  return { openModal: onOpen, closeModal: onClose, GenericModal };
}
