import { useState, useEffect, useContext } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Spacer,
  Text,
  Grid,
  GridItem,
  Box,
  Switch,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
} from "@chakra-ui/react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { Formik, Field } from "formik";
import { UserContext } from "../../../context/UserContext";
import { EditButton, HelpIconButton } from "../Buttons";
import { PersonAddress, AddressMe } from "./Address";
import { PersonAPI } from "../../../api/PersonAPI";

const BG_COLOR_FORM = "#f3f3f3";

export function CurrentUserPerson({}) {
  const user = useContext(UserContext);
  const [getPersonMe, personalInfo] = PersonAPI.getPersonMe();
  const [postPersonMe] = PersonAPI.postPersonMe();
  const [updatePersonMe] = PersonAPI.updatePersonMe();
  return (
    <PersonEditableInput
      person_data={personalInfo}
      account_id={user?.account}
      icon={<BsFillPersonLinesFill />}
      methods={[getPersonMe, postPersonMe, updatePersonMe]}
      label="Personal Info"
      add_person_label={"Info"}
      defaultOpen={true}
      address={<AddressMe />}
    />
  );
}

export function Person({
  person_id,
  account_id,
  label,
  add_person_label = "Info",
  help = "",
  icon = <BsFillPersonLinesFill />,
  onCreatePerson = () => {},
}) {
  const [getPersonByID, person_data] = PersonAPI.getPersonByID();

  const [postPerson] = PersonAPI.postPerson();
  const updatePersonByID = PersonAPI.updatePersonByID();

  const getPerson = () => {
    if (person_id) {
      getPersonByID(person_id);
    }
  };

  const updatePerson = async (data) => {
    return person_id ? updatePersonByID(person_id, data) : null;
  };

  useEffect(() => {
    getPerson();
  }, [person_id]);

  return (
    <PersonEditableInput
      person_data={person_data}
      account_id={account_id}
      add_person_label={add_person_label}
      icon={icon}
      label={label}
      help={help}
      onCreatePerson={onCreatePerson}
      methods={[getPerson, postPerson, updatePerson]}
      address={<PersonAddress person_id={person_data?.id} />}
    />
  );
}

function PersonEditableInput({
  person_data,
  account_id,
  icon,
  add_person_label,
  methods,
  label,
  address,
  help = "",
  onCreatePerson = () => {},
  defaultOpen = false,
}) {
  let person_id = person_data?.id;
  const [render, setRender] = useState(false);
  const [creationMode, setCreationMode] = useState(
    person_id == null || person_id == undefined
  );
  const accordionProps = defaultOpen ? { defaultIndex: 0 } : {};

  const [getPerson, postPerson, updatePerson] = methods;

  const { openModal, closeModal, ModalPerson } = CreateModalPerson(account_id);

  useEffect(() => {
    getPerson();
  }, [render, creationMode]);

  useEffect(() => {
    setCreationMode(person_id == null || person_id == undefined);
  }, [person_id]);

  return creationMode ? (
    <VStack w="100%">
      <HStack w="100%">
        {icon}
        <Text w={{ base: "30%", sm: "100%" }} align="left" fontWeight="bold">
          {label}
        </Text>
        {help ? <HelpIconButton title={label} help={help} /> : <></>}
      </HStack>
      <Button
        w="100%"
        variant="outline"
        colorScheme="linkedin"
        leftIcon={<PlusSquareIcon />}
        _focus={{ outline: "none" }}
        onClick={openModal}
      >
        {add_person_label}
      </Button>
      <Spacer />
      <ModalPerson
        person_data={person_data}
        acceptLabel="Create"
        onClickAccept={(new_person) => {
          postPerson(new_person).then((person_response) => {
            if (person_response?.data?.id) {
              onCreatePerson(person_response?.data?.id);
              setRender(!render);
            }
          });
          closeModal();
        }}
      />
    </VStack>
  ) : (
    <VStack align="left" w="100%">
      <HStack>
        {icon}
        <Text fontWeight="bold">{label}</Text>
        <Spacer />
        {help ? <HelpIconButton title={label} help={help} /> : <></>}
        <ModalPerson
          person_data={person_data}
          acceptLabel="Update"
          onClickAccept={(new_person) => {
            updatePerson(new_person).then(() => {
              getPerson();
            });
            closeModal();
          }}
        />
      </HStack>
      <HStack w="100%" bg={BG_COLOR_FORM} borderRadius="3px">
        <Accordion w="100%" allowToggle {...accordionProps}>
          <AccordionItem w="100%">
            <h2>
              <AccordionButton>
                <Box w="100%" textAlign="left">
                  {GetPersonSummary(person_data)}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <VStack w="100%" align="left">
                {PersonDetails(person_data, address)}
                <EditButton onClick={openModal} />
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </HStack>
    </VStack>
  );
}

function PersonForm() {
  const getPersonForm = (label, id, placeholder = "ingrese") => {
    return (
      <Field name={id}>
        {({ field }) => (
          <FormControl id={id}>
            <Grid templateColumns="repeat(11, 1fr)" gap={1}>
              <GridItem colSpan={3}>
                <FormLabel>{label}</FormLabel>
              </GridItem>
              <GridItem colSpan={8}>
                <Input
                  {...field}
                  type="text"
                  placeholder={placeholder}
                  _hover={{ background: "none" }}
                  _focus={{ outline: "none" }}
                />
              </GridItem>
            </Grid>
          </FormControl>
        )}
      </Field>
    );
  };

  return (
    <HStack w="100%" m={1} align="left">
      <VStack spacing={4} w="100%" align="left">
        {getPersonForm("First Name", "first_name", "Enter your first name")}
        {getPersonForm("Middle Name", "middle_name", "Enter your middle name")}
        {getPersonForm("Last Name", "last_name", "Enter your last name")}
        {getPersonForm("Nick Name", "nick_name", "A different way to name you")}
        {getPersonForm(
          "Business Title",
          "business_title",
          "Your title in the account"
        )}
        {getPersonForm("Phone 1", "phone_1", "Main phone number")}
        {getPersonForm("Phone 2", "phone_2", "Optional phone number")}
        <Field name={"allow_news"}>
          {({ field }) => (
            <FormControl id={"allow_news"}>
              <Grid templateColumns="repeat(11, 1fr)">
                <GridItem colSpan={3}>
                  <Center>
                    <FormLabel>Allow News</FormLabel>
                    <HelpIconButton
                      title="allow news"
                      help="If it is enabled you will receive news and notification
                            from our team."
                    />
                  </Center>
                </GridItem>
                <GridItem colSpan={8}>
                  <Center w="100%" h="100%">
                    <Switch
                      {...field}
                      isChecked={field.value || false}
                      size="md"
                      _hover={{ background: "none" }}
                      _focus={{ outline: "none" }}
                    />
                  </Center>
                </GridItem>
              </Grid>
            </FormControl>
          )}
        </Field>
      </VStack>
    </HStack>
  );
}

function PersonDetails(person_data, address) {
  const getPersonDetail = (label, value) => {
    return (
      <Grid templateColumns="repeat(11, 1fr)" gap={1}>
        <GridItem colSpan={{ md: 3, sm: 11 }}>
          <FormLabel>{label}</FormLabel>
        </GridItem>
        <GridItem colSpan={{ md: 8, sm: 11 }}>
          <Input
            type="text"
            value={value || ""}
            isReadOnly
            _hover={{ background: "none" }}
            _focus={{ outline: "none" }}
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <HStack w="100%" m={1} align="left">
      <VStack spacing={4} w="100%" align="left">
        {getPersonDetail("First Name", person_data?.first_name)}
        {getPersonDetail("Middle Name", person_data?.middle_name)}
        {getPersonDetail("Last Name", person_data?.last_name)}
        {getPersonDetail("Nick Name", person_data?.nick_name)}
        {getPersonDetail("Business Title", person_data?.business_title)}
        {address}
        {getPersonDetail("Phone 1", person_data?.phone_1)}
        {getPersonDetail("Phone 2", person_data?.phone_2)}
        <Grid templateColumns="repeat(11, 1fr)" gap={1}>
          <GridItem colSpan={3}>
            <FormLabel>Allow News {person_data?.allow_news}</FormLabel>
          </GridItem>
          <GridItem colSpan={8}>
            <Switch
              size="md"
              _hover={{ background: "none" }}
              _focus={{ outline: "none" }}
              isChecked={person_data?.allow_news || false}
            />
          </GridItem>
        </Grid>
      </VStack>
    </HStack>
  );
}

function CreateModalPerson(account_id) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ModalPerson = ({ person_data, acceptLabel, onClickAccept }) => {
    return (
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{
              first_name: person_data?.first_name || "",
              middle_name: person_data?.middle_name || "",
              last_name: person_data?.last_name || "",
              nick_name: person_data?.nick_name || "",
              business_title: person_data?.business_title || "",
              account_id: parseInt(account_id) || 0,
              phone_1: person_data?.phone_1 || "",
              phone_2: person_data?.phone_2 || "",
              allow_news: person_data?.allow_news || false,
            }}
            onSubmit={(values) => {
              onClickAccept(values);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader>Personal Information</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{PersonForm()}</ModalBody>

                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="blue" onClick={handleSubmit}>
                    {acceptLabel}
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    );
  };

  return { openModal: onOpen, closeModal: onClose, ModalPerson };
}

function GetPersonSummary(person_data) {
  return (person_data?.first_name || "") + " " + (person_data?.last_name || "");
}
