import React, { useMemo, useCallback } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { SoftwareAPI } from "../../api/SoftwareAPI";
import { FilterTable, TableHeader } from "../utils/FilterTable";
import { GoToEditPageButton } from "../utils/Buttons";

const SoftwaresTable = () => {
  const [requestSoftwares, softwaresAmount, softwares] =
    SoftwareAPI.getSoftwares();

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader title="Name" help="Software name." />,
        accessor: "getName",
      },
      {
        Header: <TableHeader title="Version" help="Software version." />,
        accessor: "getVersion",
      },
      {
        Header: (
          <TableHeader title="Description" help="Info about the software." />
        ),
        accessor: "getDescription",
      },
      {
        Header: (
          <TableHeader
            title="Installation Path"
            help="Installation path used by the software."
          />
        ),
        accessor: "getInstallationPath",
      },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (softwares !== null) {
      softwares.map((software) => {
        values.push({
          getName: (
            <ReachLink to={"/softwares/" + software.id}>
              {software.name}
            </ReachLink>
          ),
          getVersion: software.version,
          getDescription: software.description,
          getInstallationPath: software.install_path,
          getEditButton: (
            <GoToEditPageButton to={"/softwares/" + software.id} />
          ),
        });
      });
    }

    return values;
  }, [softwares]);

  const fetchData = useCallback((pageSize, pageIndex) => {
    requestSoftwares(pageSize * pageIndex, pageSize);
  }, []);

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Software Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Softwares"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={softwaresAmount}
      />
    </VStack>
  );
};

export default SoftwaresTable;
