import React, { useEffect } from "react";
import { useParams, Link as ReachLink } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/react";

import { GoBackButton } from "../utils/Buttons";
import { LicenseAPI } from "../../api/LicenseAPI";
import NumericEditableInput from "../utils/editable/Numeric";
import EditableSwitch from "../utils/editable/EditableSwitch";
import DateInput from "../utils/editable/Date";
import { parseAbsoluteToLocal } from "@internationalized/date";

const LicenseInfo = () => {
  const params = useParams();
  const licenseID = params.licenseID;
  const [getLicenseInfo, licenseInfo, licenseInfoLoaded] =
    LicenseAPI.getLicenseByID();
  const updateLicenseByID = LicenseAPI.updateLicenseByID();

  useEffect(() => {
    getLicenseInfo(licenseID);
  }, [licenseID]);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"} noOfLines={2}>
            {"License: " + (licenseInfo?.id || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {licenseInfoLoaded ? (
              <VStack w="100%" spacing={3}>
                <UnorderedList spacing={2}>
                  <ListItem>
                    <HStack w="100%">
                      <Text size="sm" fontWeight="bold">
                        Account ID:
                      </Text>
                      <ReachLink
                        to={"/accounts/" + licenseInfo.group?.account_id}
                      >
                        {licenseInfo.group?.account_id}
                      </ReachLink>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack w="100%">
                      <Text size="sm" fontWeight="bold">
                        Group Name:
                      </Text>
                      <ReachLink to={"/groups/" + licenseInfo.group_id}>
                        {licenseInfo.group?.name}
                      </ReachLink>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack w="100%">
                      <Text size="sm" fontWeight="bold">
                        Software Name:
                      </Text>
                      <ReachLink to={"/softwares/" + licenseInfo.software?.id}>
                        {licenseInfo.software?.name}
                      </ReachLink>
                    </HStack>
                  </ListItem>
                </UnorderedList>
                <LicenseFields
                  licenseInfo={licenseInfo}
                  updateLicenseByID={updateLicenseByID}
                  getLicenseInfo={getLicenseInfo}
                />
              </VStack>
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function LicenseFields({ licenseInfo, updateLicenseByID, getLicenseInfo }) {
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <DateInput
        label="Expiration time"
        value={parseAbsoluteToLocal(licenseInfo?.expiration_time)}
        helpTitle="Expiration time"
        helpContent="Time until which this license can be used to request sessions."
        onUpdate={(new_expiration_time) => {
          updateLicenseByID(licenseInfo.id, {
            expiration_time: new_expiration_time,
          }).then((response) => {
            // After updating we need to reload user info
            const updated = response?.status == 200;
            if (updated) {
              getLicenseInfo(licenseInfo.id);
            }

            return updated;
          });
        }}
      />
      <NumericEditableInput
        label="Max active sessions"
        value={licenseInfo?.maximum_active_sessions}
        help="Max amount of active sessions running simultaneously"
        onUpdate={(new_maximum_active_sessions) => {
          updateLicenseByID(licenseInfo.id, {
            maximum_active_sessions: new_maximum_active_sessions,
          }).then((response) => {
            // After updating we need to reload license info
            if (response?.status == 200) {
              getLicenseInfo(licenseInfo.id);
            }
          });
        }}
        min={0}
      />
      <NumericEditableInput
        label="Session timeout (minutes)"
        value={licenseInfo?.session_time_out}
        help="Max time allowed to run a sesion in minutes. After that
              time the session will be stopped by the server."
        onUpdate={(new_session_time_out) => {
          updateLicenseByID(licenseInfo.id, {
            session_time_out: new_session_time_out,
          }).then((response) => {
            // After updating we need to reload license info
            if (response?.status == 200) {
              getLicenseInfo(licenseInfo.id);
            }
          });
        }}
        min={0}
      />
      <EditableSwitch
        label="License Enabled"
        value={licenseInfo?.enabled}
        help="If a license is disabled it can not be used to request sessions."
        onUpdate={(new_enabled) => {
          updateLicenseByID(licenseInfo.id, {
            enabled: new_enabled,
          }).then((response) => {
            // After updating we need to reload user info
            if (response?.status == 200) {
              getLicenseInfo(licenseInfo.id);
            }
          });
        }}
      />
    </VStack>
  );
}

export default LicenseInfo;
