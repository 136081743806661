import * as React from "react";
import {
  Container,
  Heading,
  Text,
  VStack,
  Stack,
  Center,
} from "@chakra-ui/react";

import { IconContext } from "react-icons";
import { GiTrafficCone } from "react-icons/gi";

const ServerMaintenance = () => {
  return (
    <Container p={{ base: 5, md: 10 }} mx="auto">
      <Center>
        <VStack
          spacing={4}
          px={2}
          alignItems={{ base: "center", sm: "center" }}
        >
          <Stack justifyContent="center" alignItems="center">
            <IconContext.Provider value={{ color: "orange", size: 70 }}>
              <GiTrafficCone />
            </IconContext.Provider>
          </Stack>
          <Heading>Server maintenance</Heading>
          <Text textAlign="center">
            VisionX's Servers are under maintenance. Please try again later or
            contact our customer service.
          </Text>
        </VStack>
      </Center>
    </Container>
  );
};

export default ServerMaintenance;
