import React, { useMemo, useContext, useCallback } from "react";
import { Heading, Center, StackDivider, VStack } from "@chakra-ui/react";
import { FilterTable, TableHeader } from "../utils/FilterTable";
import { AccountContext } from "../../context/AccountContext";
import { ProductionAPI } from "../../api/ProductionAPI";
import { HelpIconButton } from "../utils/Buttons";
import { calculateDiffTime, splitDateTime } from "../utils/Time";

const ProductionsTable = () => {
  const workingAccount = useContext(AccountContext);
  const [getProductionsByAccount, productionsAmount, productions, loaded] =
    ProductionAPI.productionsByAccount();

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader title="ID" help="Production identifier." />,
        accessor: "getID",
      },
      {
        Header: (
          <TableHeader title="Product Name" help="Product name created." />
        ),
        accessor: "getProductName",
      },
      {
        Header: (
          <TableHeader title="Count" help="Amount of products created." />
        ),
        accessor: "getProductionCount",
      },
      {
        Header: (
          <TableHeader
            title="Order Identifier"
            help="Order identifier for this production."
          />
        ),
        accessor: "getOrderIdentifier",
      },
      {
        Header: (
          <TableHeader
            title="Session start"
            help="When was the product started to create."
          />
        ),
        accessor: "getSessionStart",
      },
      {
        Header: (
          <TableHeader
            title="Session end"
            help="When was the product created."
          />
        ),
        accessor: "getSessionEnd",
      },
      {
        Header: (
          <TableHeader
            title="Elapsed time"
            help="Time used to create the product."
          />
        ),
        accessor: "getElapsedTime",
      },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (productions !== null) {
      productions.map((production) => {
        values.push({
          getID: production.id,
          getProductName: production.product.name,
          getProductionCount: production.count,
          getOrderIdentifier: production.order_identifier,
          getSessionStart: splitDateTime(production.user_session.created_at),
          getSessionEnd: splitDateTime(production.user_session.end),
          getElapsedTime:
            production.user_session.end && production.user_session.created_at
              ? calculateDiffTime(
                  production.user_session.created_at,
                  production.user_session.end
                )
              : "--",
        });
      });
    }
    return values;
  }, [productions]);

  const fetchData = useCallback(
    (pageSize, pageIndex) => {
      getProductionsByAccount(
        workingAccount.id,
        pageSize * pageIndex,
        pageSize
      );
    },
    [workingAccount.id]
  );

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Center>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Productions Table
        </Heading>
        <HelpIconButton
          title="productions"
          help="A production is created by users of the account after running
                a session. A new production item is made up of a product and
                the number (count) of times it was produced."
        />
      </Center>
      <FilterTable
        tableCaption={"VisionX Productions"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={productionsAmount}
        resetTableDependencies={[workingAccount.id]}
      />
    </VStack>
  );
};

export default ProductionsTable;
