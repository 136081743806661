import { useEffect } from "react";
import { useParams, Link as ReachLink } from "react-router-dom";
import {
  Heading,
  StackDivider,
  Center,
  VStack,
  HStack,
  Spacer,
  Spinner,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { FaUserTag } from "react-icons/fa";
import { MdDescription } from "react-icons/md";
import { GoBackButton } from "../utils/Buttons";
import { DataRoleAPI } from "../../api/DataRoleAPI";
import GenericEditableInput from "../utils/editable/Generic";
import NumericEditableInput from "../utils/editable/Numeric";

const DataRoleInfo = () => {
  const params = useParams();
  const dataRoleID = params.dataRoleID;
  const [getDataRoleInfo, dataRoleInfo, dataRoleInfoLoaded] =
    DataRoleAPI.getDataRoleByID();
  const updateDataRoleByID = DataRoleAPI.updateDataRoleByID();

  useEffect(() => {
    getDataRoleInfo(dataRoleID);
  }, [dataRoleID]);

  return (
    <VStack spacing={4} padding={4} margin={10}>
      <HStack w="100%">
        <GoBackButton w="110px" />
        <Spacer />
        <VStack pr="110px">
          <Heading fontSize={"2xl"} textAlign={"left"}>
            {"Data Role: " + (dataRoleInfo?.name || "")}
          </Heading>
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
        </VStack>
        <Spacer />
      </HStack>
      <VStack bg="white" rounded="md" w="100%">
        <HStack mb={4} w="70%">
          <Center w="100%">
            {dataRoleInfoLoaded || true ? (
              <VStack w="100%" spacing={3}>
                <UnorderedList spacing={2}>
                  <ListItem>
                    <HStack w="100%">
                      <Text size="sm" fontWeight="bold">
                        Data Role Code: {dataRoleInfo?.code}
                      </Text>
                    </HStack>
                  </ListItem>
                </UnorderedList>
                <DataRoleFields
                  dataRoleInfo={dataRoleInfo}
                  updateDataRoleByID={updateDataRoleByID}
                  getDataRoleInfo={getDataRoleInfo}
                />
              </VStack>
            ) : (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            )}
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

function DataRoleFields({ dataRoleInfo, updateDataRoleByID, getDataRoleInfo }) {
  return (
    <VStack w="100%" bg="white" borderRadius="lg" spacing={10} mt={10}>
      <GenericEditableInput
        label="Name"
        icon={<FaUserTag color="gray.800" />}
        value={dataRoleInfo?.name}
        help="Name of the data role."
        onUpdate={(new_name) => {
          updateDataRoleByID(dataRoleInfo.id, {
            name: new_name,
          }).then((response) => {
            // After updating we need to reload data role info
            if (response?.status == 200) {
              getDataRoleInfo(dataRoleInfo.id);
            }
          });
        }}
      />
      <GenericEditableInput
        label="Description"
        icon={<MdDescription color="gray.800" />}
        help="Brief description of the data role."
        value={dataRoleInfo?.description}
        onUpdate={(new_description) => {
          updateDataRoleByID(dataRoleInfo.id, {
            description: new_description,
          }).then((response) => {
            // After updating we need to reload data role info
            if (response?.status == 200) {
              getDataRoleInfo(dataRoleInfo.id);
            }
          });
        }}
      />
      <NumericEditableInput
        label="Code"
        value={dataRoleInfo?.code}
        help="Data role code values"
        onUpdate={(new_code) => {
          updateDataRoleByID(dataRoleInfo.id, {
            code: new_code,
          }).then((response) => {
            // After updating we need to reload data role info
            if (response?.status == 200) {
              getDataRoleInfo(dataRoleInfo.id);
            }
          });
        }}
      />
    </VStack>
  );
}

export default DataRoleInfo;
