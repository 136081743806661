import React, { useMemo, useCallback } from "react";
import { Link as ReachLink } from "react-router-dom";

import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { AccountAPI } from "../../api/AccountAPI";
import { FilterTable, TableHeader } from "../utils/FilterTable";
import { GoToEditPageButton } from "../utils/Buttons";

const AccountsTable = () => {
  const [getAcounts, accountsAmount, accounts] = AccountAPI.getAccounts();

  const columns = useMemo(
    () => [
      {
        Header: (
          <TableHeader
            title="Account Name"
            help="Unique account name (without whitespaces)."
          />
        ),
        accessor: "getAccountName",
      },
      {
        Header: (
          <TableHeader
            title="Enabled"
            help="If an account is disabled, its users won't be able to request
                  sessions."
          />
        ),
        accessor: "getEnabled",
      },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (accounts !== null) {
      accounts.map((account) => {
        values.push({
          getAccountName: (
            <ReachLink to={"/accounts/" + account.id}>
              {account.name}
            </ReachLink>
          ),
          getEnabled: account.enabled ? "yes" : "no",
          getEditButton: (
            <GoToEditPageButton to={"/accounts/" + account.id} />
          ),
        });
      });
    }

    return values;
  }, [accounts]);

  const fetchData = useCallback((pageSize, pageIndex) => {
    getAcounts(pageSize * pageIndex, pageSize);
  }, []);

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Accounts Table
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Accounts"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={accountsAmount}
      />
    </VStack>
  );
};

export default AccountsTable;
