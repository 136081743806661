import React, { useMemo, useCallback } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Heading, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { FilterTable, TableHeader } from "../utils/FilterTable";
import { GoToEditPageButton } from "../utils/Buttons";
import { DataRoleAPI } from "../../api/DataRoleAPI";

const DataRoleTable = () => {
  const [getDataRoles, dataRolesAmount, dataRoles] = DataRoleAPI.getDataRoles();

  const columns = useMemo(
    () => [
      {
        Header: <TableHeader title="Name" help="Data role name." />,
        accessor: "getName",
      },
      {
        Header: <TableHeader title="Code" help="Data role code." />,
        accessor: "getCode",
      },
      {
        Header: (
          <TableHeader
            title="Description"
            help="Describe data role function."
          />
        ),
        accessor: "getDescription",
      },
      { Header: "", accessor: "getEditButton", disableFilters: true },
    ],
    []
  );

  const data = useMemo(() => {
    const values = [];

    if (dataRoles !== null) {
      dataRoles.map((dataRole) => {
        values.push({
          getName: (
            <ReachLink to={"/data_roles/" + dataRole.id}>
              {dataRole.name}
            </ReachLink>
          ),
          getCode: dataRole.code,
          getDescription: dataRole.description,
          getEditButton: (
            <GoToEditPageButton to={"/data_roles/" + dataRole.id} />
          ),
        });
      });
    }
    return values;
  }, [dataRoles]);

  const fetchData = useCallback((pageSize, pageIndex) => {
    getDataRoles(pageSize * pageIndex, pageSize);
  }, []);

  return (
    <VStack
      divider={<StackDivider borderWidth="2px" borderColor="gray.200" />}
      spacing={4}
      align="stretch"
      padding={4}
    >
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"center"}>
          VisionX Data Roles
        </Heading>
      </Stack>
      <FilterTable
        tableCaption={"VisionX Data Roles"}
        columns={columns}
        data={data}
        fetchData={fetchData}
        dataAmount={dataRolesAmount}
      />
    </VStack>
  );
};

export default DataRoleTable;
