import React, { createContext, useEffect, useState } from "react";
import axios from "../api/axios";
import * as C from "../config/Constants";

export const ServerStateContext = createContext();

export const ServerStateProvider = (props) => {
  const [serverRunning, setServerRunning] = useState(false);

  useEffect(() => {
    const fetchMe = async () => {
      let response = null;
      try {
        // Note: No need for auth token, this endpoint is just used to
        // check server availability.
        response = await axios.get(C.USER_ME_URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + null,
          },
        });
      } catch (err) {
        console.log("ERR" + err?.response);
        response = err?.response;
      }

      setServerRunning(
        response &&
          response.status &&
          (response.status >= 500 || response.status < 600)
      );
    };
    fetchMe();
  });

  return (
    <ServerStateContext.Provider value={{ isRunning: serverRunning }}>
      {props.children}
    </ServerStateContext.Provider>
  );
};
