import React, { useContext } from "react";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Switch,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  StackDivider,
  VStack,
  HStack,
  Spacer,
  Center,
  Text,
} from "@chakra-ui/react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import * as C from "../../config/Constants";

import { hasWhiteSpaces, isValidEmail } from "../utils/Utils";
import { AccountAPI } from "../../api/AccountAPI";
import { HelpIconButton } from "../utils/Buttons";
import { AccountContext } from "../../context/AccountContext";

const AccountWithAdminForm = () => {
  const [submittingData, setSubmittingData] = React.useState(false);
  const workingAccount = useContext(AccountContext);
  const [setSendEmail, submitAccount] = AccountAPI.postAccountWithAdmin();

  return (
    <VStack spacing={4} padding={4}>
      <Stack>
        <Heading fontSize={"2xl"} textAlign={"left"}>
          Create account
        </Heading>
      </Stack>
      <Box bg="white" w="60vh">
        <HStack mb={4} w="100%">
          <Spacer />
          <StackDivider w="100%" borderWidth="2px" borderColor="gray.200" />
          <Spacer />
        </HStack>
        <Text as="i">
          An account represents your entire organization. Here you must add an
          administrator who will be able to create groups and add users to them.
        </Text>
        <Formik
          initialValues={{
            accountName: "",
            accountEnabled: true,
            accountAdminName: "",
            accountAdminEmail: "",
            tokenLifetime: 1,
          }}
          onSubmit={async (values, { resetForm }) => {
            submitAccount({
              account: {
                name: values["accountName"],
                enabled: values["accountEnabled"],
              },
              user: {
                name: values["accountAdminName"],
                email: values["accountAdminEmail"],
              },
              tokenLifetime: values["tokenLifetime"],
            }).then((response) => {
              setSubmittingData(false);
              if (response?.status == 200) {
                resetForm();
              }
            });
            workingAccount.refresh();
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={errors.accountName && touched.accountName}
                >
                  <HStack>
                    <FormLabel htmlFor="accountName">
                      New account name
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="account name"
                      help="New organization's name (without whitespaces)."
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="accountName"
                    name="accountName"
                    type="accountName"
                    variant="filled"
                    placeholder="New organization's name (without whitespaces)"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Account name is required";
                      }
                      if (hasWhiteSpaces(value)) {
                        error = "Account name can't have whitespaces";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.accountName}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={
                    errors.accountAdminName && touched.accountAdminName
                  }
                >
                  <HStack>
                    <FormLabel htmlFor="accountAdminName">
                      Account administrator name
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="admin name"
                      help="User name for the account administrator"
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="accountAdminName"
                    name="accountAdminName"
                    type="accountAdminName"
                    variant="filled"
                    placeholder="User name for the account administrator"
                    validate={(value) => {
                      let error;
                      if (!value) {
                        error = "Administrator name is required";
                      }
                      if (hasWhiteSpaces(value)) {
                        error = "Administrator name can't have whitespaces";
                      }
                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.accountAdminName}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={
                    errors.accountAdminEmail && touched.accountAdminEmail
                  }
                >
                  <HStack>
                    <FormLabel htmlFor="name">
                      Account administrator email
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="admin email"
                      help="Email used by the account admin to set its password"
                    />
                  </HStack>
                  <Field
                    as={Input}
                    id="accountAdminEmail"
                    name="accountAdminEmail"
                    type="accountAdminEmail"
                    placeholder="example: admin@email.com"
                    variant="filled"
                    validate={(value) => {
                      if (!value) {
                        return "Administrator email is required";
                      }
                      if (!isValidEmail(value)) {
                        return "Administrator email is not valid";
                      }
                      return null;
                    }}
                  />
                  <FormErrorMessage>
                    {errors.accountAdminEmail}
                  </FormErrorMessage>
                </FormControl>
                <FormControl hidden={true} display="flex" alignItems="center">
                  <FormLabel htmlFor="enabled" mb="0">
                    Send email to set password
                  </FormLabel>
                  <Switch
                    id="sendEmail"
                    name="sendEmail"
                    size="md"
                    defaultChecked
                    onChange={(e) => {
                      setSendEmail(e.target.checked);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <HStack>
                    <FormLabel htmlFor="tokenLifetime">
                      Token duration (hours)
                    </FormLabel>
                    <Spacer />
                    <HelpIconButton
                      title="token duration"
                      help="Time allowed to the admin account to set its password"
                    />
                  </HStack>
                  <Field name="tokenLifetime">
                    {({ field, form }) => (
                      <NumberInput
                        defaultValue={C.TOKEN_LIFETIME}
                        min={0}
                        id="tokenLifetime"
                        {...field}
                        onChange={(val) => form.setFieldValue(field.name, val)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  </Field>
                </FormControl>

                <FormControl>
                  <HStack w="100%">
                    <FormLabel m={0} htmlFor="accountEnabled">
                      Account enabled
                    </FormLabel>
                    <HelpIconButton
                      title="account enabled"
                      help="If the account is disabled it can not be used by
                            its users in anyway."
                    />
                    <Spacer />
                    <Field id="accountEnabled" name="accountEnabled">
                      {({ field, form }) => (
                        <Switch
                          size="md"
                          defaultChecked
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.checked);
                          }}
                        />
                      )}
                    </Field>
                  </HStack>
                </FormControl>

                <Center w="100%">
                  <Button
                    isLoading={submittingData}
                    loadingText="Submitting"
                    type="submit"
                    colorScheme="purple"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      submittingData
                    }
                    onClick={(value) => {
                      handleSubmit(value);
                      setSubmittingData(true);
                    }}
                  >
                    Create account
                  </Button>
                </Center>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default AccountWithAdminForm;
